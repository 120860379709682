// App CSS
// --------------------------------------------------

// Imports
// -------------------------

// Bootstrap Variables
@import '~bootstrap/scss/functions';
@import './bootstrapVariables'; // "~bootstrap/scss/variables";
@import '~bootstrap/scss/mixins';
@import '~bootstrap/scss/utilities';
@import '~bootstrap/scss/root';
@import '~bootstrap/scss/reboot';
@import '~bootstrap/scss/type';
// @import "~bootstrap/scss/images";
@import '~bootstrap/scss/containers';
@import '~bootstrap/scss/grid';
@import '~bootstrap/scss/tables';
@import '~bootstrap/scss/forms';
@import '~bootstrap/scss/buttons';
@import '~bootstrap/scss/transitions';
@import '~bootstrap/scss/dropdown';
@import '~bootstrap/scss/button-group';
@import '~bootstrap/scss/nav';
@import '~bootstrap/scss/navbar';
// @import "~bootstrap/scss/card";
// @import "~bootstrap/scss/accordion";
// @import "~bootstrap/scss/breadcrumb";
// @import "~bootstrap/scss/pagination";
@import '~bootstrap/scss/badge';
@import '~bootstrap/scss/alert';
// @import "~bootstrap/scss/progress";
@import '~bootstrap/scss/list-group';
@import '~bootstrap/scss/close';
// @import "~bootstrap/scss/toasts";
@import '~bootstrap/scss/modal';
@import '~bootstrap/scss/tooltip';
// @import "~bootstrap/scss/popover";
// @import "~bootstrap/scss/carousel";
// @import "~bootstrap/scss/spinners";
// @import "~bootstrap/scss/offcanvas";
// @import "~bootstrap/scss/placeholders";
@import '~bootstrap/scss/helpers';
@import '~bootstrap/scss/utilities/api';

// Web Fonts
@import url('https://fonts.googleapis.com/css2?family=Oswald:wght@200;300;400;500;600;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,300;0,400;0,600;0,700;0,800;1,300;1,400;1,600;1,700;1,800&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Raleway:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');

// Variables
@import './variables';

// Controls
@import 'components/Buttons/Buttons.scss';
@import 'components/Modal/Modal.scss';

// Body
// -------------------------

html,
body {
  background-color: $hs-shade-white;
  font-family:
    'Open Sans',
    -apple-system,
    BlinkMacSystemFont,
    'Segoe UI',
    Roboto,
    'Helvetica Neue',
    Arial,
    'Noto Sans',
    sans-serif,
    'Apple Color Emoji',
    'Segoe UI Emoji',
    'Segoe UI Symbol',
    'Noto Color Emoji';
  margin: 0;
  padding: 0;
}

.v-hide {
  visibility: hidden !important;
}

// SVG's
// -------------------------

.hs-svg-inherit {
  fill: currentcolor;
}

.hs-svg-gray {
  fill: $hs-shade-540;
}

.hs-svg-primary {
  fill: $hs-color-primary;
}

// App
// -------------------------

.hs-app,
.hs-root {
  display: inline-block;
  margin: 0;
  padding: 0;
  position: relative;
  width: 100%;
}

// Media Queries
// -------------------------

// Small Devices (landscape phones, less than 768px)
@media (max-width: 767.98px) {
  html,
  body {
    overflow-y: auto;
  }
}
