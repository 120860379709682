// Lab Header CSS
// ----------------------------------------

// Imports
// -------------------------

@import '../../variables';

// Header
// -------------------------

.hs-header {
  align-content: flex-start;
  align-items: flex-start;
  display: flex;
  flex-flow: row nowrap;
  gap: 0;
  justify-content: space-between;
  margin: 40px auto 20px;
  padding: 0;
  position: relative;
  width: 100%;

  > h1 {
    color: $hs-color-active;
    font-family:
      'Montserrat',
      -apple-system,
      BlinkMacSystemFont,
      'Segoe UI',
      Roboto,
      'Helvetica Neue',
      Arial,
      'Noto Sans',
      sans-serif,
      'Apple Color Emoji',
      'Segoe UI Emoji',
      'Segoe UI Symbol',
      'Noto Color Emoji';
    font-size: 2rem; // 32px
    font-weight: 700;
    margin: 4px 0 0;
    padding: 0;
    position: relative;
    text-transform: uppercase;
  }

  .hs-controls {
    align-content: flex-start;
    align-items: stretch;
    display: flex;
    flex-flow: row nowrap;
    gap: 0;
    justify-content: flex-end;
    margin: 0 -5px 0 0;
    padding: 0;
  }
}

.TF .hs-header {
  > h1 {
    color: $hs-shade-black;
  }
}

// Media Queries
// -------------------------

// X-Large Devices (large desktops, less than 1400px)
// @media (max-width: 1399.98px) {

// }

// Large Devices (desktops, less than 1200px)
// @media (max-width: 1199.98px) {

// }

// Medium Devices (tablets, less than 992px)
@media (max-width: 991.98px) {
  .hs-header {
    align-items: stretch;
    flex-flow: column nowrap;
    justify-content: center;
    margin: 15px auto;
    text-align: center;

    > h1 {
      font-size: 1.5rem; // 24px
      margin: 25px 0 0;
      order: 2;
    }

    .hs-controls {
      flex-flow: row wrap;
      justify-content: center;
      margin: 0;
      order: 1;
    }
  }
}

// Small Devices (landscape phones, less than 768px)
// @media (max-width: 767.98px) {

// }

// X-Small Devices (portrait phones, less than 576px)
@media (max-width: 575.98px) {
  .hs-header {
    > h1 {
      font-size: 1.25rem; // 20px
    }
  }
}
