// Buttons CSS
// --------------------------------------------------

// Imports
// -------------------------

@import '../../variables';

// Bootstrap Overrides
// -------------------------

a {
  display: inline-block;
  text-decoration: none;

  &:focus,
  &:focus-visible {
    -webkit-box-shadow: 0 0 0 5px rgba($hs-color-primary, 0.25);
    box-shadow: 0 0 0 5px rgba($hs-color-primary, 0.25);
    outline: 0;
  }

  &:focus,
  &:focus-visible,
  &.focus,
  &:hover {
    text-decoration: underline;
  }
}

a.link-style {
  text-decoration: underline;
  color: blue;
}

.btn {
  border-width: 2px;
  border-radius: calc($hs-btn-md-min-size / 2);
  font-size: 1rem; // 16px
  //font-family: "Raleway", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  font-family:
    'Montserrat',
    -apple-system,
    BlinkMacSystemFont,
    'Segoe UI',
    Roboto,
    'Helvetica Neue',
    Arial,
    'Noto Sans',
    sans-serif,
    'Apple Color Emoji',
    'Segoe UI Emoji',
    'Segoe UI Symbol',
    'Noto Color Emoji';
  font-style: normal;
  font-weight: 400;
  //line-height: 1;
  margin: 5px;
  min-height: $hs-btn-md-min-size;
  min-width: $hs-btn-md-min-size;
  max-width: 520px;
  padding: 0.6875rem 1.375rem; // 11px 22px

  svg {
    &,
    &:not(:root) {
      display: inline-block;
      fill: currentcolor;
      font-size: inherit;
      height: 1rem; // 16px
      margin: 0 6px 0 0;
      overflow: visible;
      position: relative;
      vertical-align: -0.125em;
      width: 1rem; // 16px
    }
  }

  &.btn-sm {
    border-radius: calc($hs-btn-sm-min-size / 2);
    font-size: 1rem; // 16px
    //line-height: 1;
    min-height: $hs-btn-sm-min-size;
    min-width: $hs-btn-sm-min-size;
    padding: 0.3125rem 0.9375rem; // 5px 15px
  }

  &.btn-lg {
    border-radius: calc($hs-btn-lg-min-size / 2);
    font-size: 1rem; // 16px
    //line-height: 1;
    min-height: $hs-btn-lg-min-size;
    min-width: $hs-btn-lg-min-size;
    padding: 1rem 2rem; // 16px 32px
  }

  &.input-radius {
    border-radius: 4px !important;
  }

  &:focus,
  &.focus {
    -webkit-box-shadow: 0 0 0 5px rgba($hs-color-primary, 0.25);
    box-shadow: 0 0 0 5px rgba($hs-color-primary, 0.25);
  }
}

// Buttons
// -------------------------

.uix-btn-default {
  &,
  &.btn {
    background-color: transparent;
    border-color: transparent;
    border-width: 1px;
    color: $hs-color-active;

    &:focus,
    &.focus {
      background-color: $hs-color-info;
      border-color: $hs-color-info;
      -webkit-box-shadow: 0 0 0 5px rgba($hs-color-info, 0.33);
      box-shadow: 0 0 0 5px rgba($hs-color-info, 0.33);
      color: $hs-shade-white;
    }

    &:hover {
      background-color: $hs-color-info;
      border-color: $hs-color-info;
      color: $hs-shade-white;
    }

    &:not(:disabled):not(.disabled):active,
    &:not(:disabled):not(.disabled).active,
    .show > &.dropdown-toggle {
      background-color: darken($hs-color-info, 2.5%);
      border-color: darken($hs-color-info, 2.5%);
      color: $hs-shade-white;
    }

    &:not(:disabled):not(.disabled):active:focus,
    &:not(:disabled):not(.disabled).active:focus,
    .show > &.dropdown-toggle:focus {
      -webkit-box-shadow: 0 0 0 5px rgba($hs-color-info, 0.33);
      box-shadow: 0 0 0 5px rgba($hs-color-info, 0.33);
    }
  }
}

.btn-primary {
  &,
  &.btn {
    background-color: $hs-color-navy-blue;
    border-color: $hs-color-primary;
    color: $hs-shade-white;

    &:focus,
    &.focus {
      background-color: darken($hs-color-primary, 10%);
      border-color: darken($hs-color-primary, 10%);
      -webkit-box-shadow: 0 0 0 5px rgba($hs-color-primary, 0.33);
      box-shadow: 0 0 0 5px rgba($hs-color-primary, 0.33);
      color: $hs-shade-white;
    }

    &:hover {
      background-color: darken($hs-color-primary, 10%);
      border-color: darken($hs-color-primary, 10%);
      color: $hs-shade-white;
    }

    &:not(:disabled):not(.disabled):active,
    &:not(:disabled):not(.disabled).active,
    .show > &.dropdown-toggle {
      background-color: darken($hs-color-primary, 12.5%);
      border-color: darken($hs-color-primary, 12.5%);
      color: $hs-shade-white;
    }

    &:not(:disabled):not(.disabled):active:focus,
    &:not(:disabled):not(.disabled).active:focus,
    .show > &.dropdown-toggle:focus {
      -webkit-box-shadow: 0 0 0 5px rgba($hs-color-primary, 0.33);
      box-shadow: 0 0 0 5px rgba($hs-color-primary, 0.33);
    }
  }
}

.btn-secondary {
  &,
  &.btn {
    background-color: $hs-color-secondary;
    border-color: $hs-color-secondary;
    color: $hs-color-active;

    &:focus,
    &.focus {
      background-color: darken($hs-color-secondary, 10%);
      border-color: darken($hs-color-secondary, 10%);
      -webkit-box-shadow: 0 0 0 5px rgba($hs-color-secondary, 0.66);
      box-shadow: 0 0 0 5px rgba($hs-color-secondary, 0.66);
      color: $hs-color-active;
    }

    &:hover {
      background-color: darken($hs-color-secondary, 10%);
      border-color: darken($hs-color-secondary, 10%);
      color: $hs-color-active;
    }

    &:not(:disabled):not(.disabled):active,
    &:not(:disabled):not(.disabled).active,
    .show > &.dropdown-toggle {
      background-color: darken($hs-color-secondary, 12.5%);
      border-color: darken($hs-color-secondary, 12.5%);
      color: $hs-color-active;
    }

    &:not(:disabled):not(.disabled):active:focus,
    &:not(:disabled):not(.disabled).active:focus,
    .show > &.dropdown-toggle:focus {
      -webkit-box-shadow: 0 0 0 5px rgba($hs-color-secondary, 0.66);
      box-shadow: 0 0 0 5px rgba($hs-color-secondary, 0.66);
    }
  }
}

.uix-btn-active {
  &,
  &.btn {
    background-color: $hs-color-active;
    border-color: $hs-color-active;
    color: $hs-shade-white;

    &:focus,
    &.focus {
      background-color: darken($hs-color-active, 10%);
      border-color: darken($hs-color-active, 10%);
      -webkit-box-shadow: 0 0 0 5px rgba($hs-color-active, 0.33);
      box-shadow: 0 0 0 5px rgba($hs-color-active, 0.33);
      color: $hs-shade-white;
    }

    &:hover {
      background-color: darken($hs-color-active, 10%);
      border-color: darken($hs-color-active, 10%);
      color: $hs-shade-white;
    }

    &:not(:disabled):not(.disabled):active,
    &:not(:disabled):not(.disabled).active,
    .show > &.dropdown-toggle {
      background-color: darken($hs-color-active, 12.5%);
      border-color: darken($hs-color-active, 12.5%);
      color: $hs-shade-white;
    }

    &:not(:disabled):not(.disabled):active:focus,
    &:not(:disabled):not(.disabled).active:focus,
    .show > &.dropdown-toggle:focus {
      -webkit-box-shadow: 0 0 0 5px rgba($hs-color-active, 0.33);
      box-shadow: 0 0 0 5px rgba($hs-color-active, 0.33);
    }
  }
}

.btn-danger,
.uix-btn-error {
  &,
  &.btn {
    background-color: $hs-color-error;
    border-color: $hs-color-error;
    color: $hs-shade-white;

    &:focus,
    &.focus {
      background-color: darken($hs-color-error, 10%);
      border-color: darken($hs-color-error, 10%);
      -webkit-box-shadow: 0 0 0 5px rgba($hs-color-error, 0.33);
      box-shadow: 0 0 0 5px rgba($hs-color-error, 0.33);
      color: $hs-shade-white;
    }

    &:hover {
      background-color: darken($hs-color-error, 10%);
      border-color: darken($hs-color-error, 10%);
      color: $hs-shade-white;
    }

    &:not(:disabled):not(.disabled):active,
    &:not(:disabled):not(.disabled).active,
    .show > &.dropdown-toggle {
      background-color: darken($hs-color-error, 12.5%);
      border-color: darken($hs-color-error, 12.5%);
      color: $hs-shade-white;
    }

    &:not(:disabled):not(.disabled):active:focus,
    &:not(:disabled):not(.disabled).active:focus,
    .show > &.dropdown-toggle:focus {
      -webkit-box-shadow: 0 0 0 5px rgba($hs-color-error, 0.33);
      box-shadow: 0 0 0 5px rgba($hs-color-error, 0.33);
    }
  }
}

.btn-success {
  &,
  &.btn {
    background-color: $hs-color-success;
    border-color: $hs-color-success;
    color: $hs-shade-black;

    &:focus,
    &.focus {
      background-color: darken($hs-color-success, 10%);
      border-color: darken($hs-color-success, 10%);
      -webkit-box-shadow: 0 0 0 5px rgba($hs-color-success, 0.33);
      box-shadow: 0 0 0 5px rgba($hs-color-success, 0.33);
      color: $hs-shade-black;
    }

    &:hover {
      background-color: darken($hs-color-success, 10%);
      border-color: darken($hs-color-success, 10%);
      color: $hs-shade-black;
    }

    &:not(:disabled):not(.disabled):active,
    &:not(:disabled):not(.disabled).active,
    .show > &.dropdown-toggle {
      background-color: darken($hs-color-success, 12.5%);
      border-color: darken($hs-color-success, 12.5%);
      color: $hs-shade-white;
    }

    &:not(:disabled):not(.disabled):active:focus,
    &:not(:disabled):not(.disabled).active:focus,
    .show > &.dropdown-toggle:focus {
      -webkit-box-shadow: 0 0 0 5px rgba($hs-color-success, 0.33);
      box-shadow: 0 0 0 5px rgba($hs-color-success, 0.33);
    }
  }
}

.btn-warning {
  &,
  &.btn {
    background-color: $hs-color-warning;
    border-color: $hs-color-warning;
    color: $hs-shade-white;

    &:focus,
    &.focus {
      background-color: darken($hs-color-warning, 10%);
      border-color: darken($hs-color-warning, 10%);
      -webkit-box-shadow: 0 0 0 5px rgba($hs-color-warning, 0.33);
      box-shadow: 0 0 0 5px rgba($hs-color-warning, 0.33);
      color: $hs-shade-white;
    }

    &:hover {
      background-color: darken($hs-color-warning, 10%);
      border-color: darken($hs-color-warning, 10%);
      color: $hs-shade-white;
    }

    &:not(:disabled):not(.disabled):active,
    &:not(:disabled):not(.disabled).active,
    .show > &.dropdown-toggle {
      background-color: darken($hs-color-warning, 12.5%);
      border-color: darken($hs-color-warning, 12.5%);
      color: $hs-shade-white;
    }

    &:not(:disabled):not(.disabled):active:focus,
    &:not(:disabled):not(.disabled).active:focus,
    .show > &.dropdown-toggle:focus {
      -webkit-box-shadow: 0 0 0 5px rgba($hs-color-warning, 0.33);
      box-shadow: 0 0 0 5px rgba($hs-color-warning, 0.33);
    }
  }
}

.btn-info {
  &,
  &.btn {
    background-color: $hs-color-info;
    border-color: $hs-color-info;
    color: $hs-shade-white;

    &:focus,
    &.focus {
      background-color: darken($hs-color-info, 10%);
      border-color: darken($hs-color-info, 10%);
      -webkit-box-shadow: 0 0 0 5px rgba($hs-color-info, 0.33);
      box-shadow: 0 0 0 5px rgba($hs-color-info, 0.33);
      color: $hs-shade-white;
    }

    &:hover {
      background-color: darken($hs-color-info, 10%);
      border-color: darken($hs-color-info, 10%);
      color: $hs-shade-white;
    }

    &:not(:disabled):not(.disabled):active,
    &:not(:disabled):not(.disabled).active,
    .show > &.dropdown-toggle {
      background-color: darken($hs-color-info, 12.5%);
      border-color: darken($hs-color-info, 12.5%);
      color: $hs-shade-white;
    }

    &:not(:disabled):not(.disabled):active:focus,
    &:not(:disabled):not(.disabled).active:focus,
    .show > &.dropdown-toggle:focus {
      -webkit-box-shadow: 0 0 0 5px rgba($hs-color-info, 0.33);
      box-shadow: 0 0 0 5px rgba($hs-color-info, 0.33);
    }
  }
}

.btn-light {
  &,
  &.btn {
    background-color: $hs-shade-white;
    border-color: $hs-shade-white;
    color: $hs-color-dark;

    &:focus,
    &.focus {
      background-color: darken($hs-shade-white, 5%);
      border-color: darken($hs-shade-white, 5%);
      -webkit-box-shadow: 0 0 0 5px rgba($hs-shade-white, 0.33);
      box-shadow: 0 0 0 5px rgba($hs-shade-white, 0.33);
      color: $hs-color-dark;
    }

    &:hover {
      background-color: darken($hs-shade-white, 5%);
      border-color: darken($hs-shade-white, 5%);
      color: $hs-color-dark;
    }

    &:not(:disabled):not(.disabled):active,
    &:not(:disabled):not(.disabled).active,
    .show > &.dropdown-toggle {
      background-color: darken($hs-shade-white, 7.5%);
      border-color: darken($hs-shade-white, 7.5%);
      color: $hs-color-dark;
    }

    &:not(:disabled):not(.disabled):active:focus,
    &:not(:disabled):not(.disabled).active:focus,
    .show > &.dropdown-toggle:focus {
      -webkit-box-shadow: 0 0 0 5px rgba($hs-shade-white, 0.33);
      box-shadow: 0 0 0 5px rgba($hs-shade-white, 0.33);
    }
  }
}

.btn-dark {
  &,
  &.btn {
    background-color: $hs-color-dark;
    border-color: $hs-color-dark;
    color: $hs-shade-white;

    &:focus,
    &.focus {
      background-color: darken($hs-color-dark, 10%);
      border-color: darken($hs-color-dark, 10%);
      -webkit-box-shadow: 0 0 0 5px rgba($hs-color-dark, 0.33);
      box-shadow: 0 0 0 5px rgba($hs-color-dark, 0.33);
      color: $hs-shade-white;
    }

    &:hover {
      background-color: darken($hs-color-dark, 10%);
      border-color: darken($hs-color-dark, 10%);
      color: $hs-shade-white;
    }

    &:not(:disabled):not(.disabled):active,
    &:not(:disabled):not(.disabled).active,
    .show > &.dropdown-toggle {
      background-color: darken($hs-color-dark, 12.5%);
      border-color: darken($hs-color-dark, 12.5%);
      color: $hs-shade-white;
    }

    &:not(:disabled):not(.disabled):active:focus,
    &:not(:disabled):not(.disabled).active:focus,
    .show > &.dropdown-toggle:focus {
      -webkit-box-shadow: 0 0 0 5px rgba($hs-color-dark, 0.33);
      box-shadow: 0 0 0 5px rgba($hs-color-dark, 0.33);
    }
  }
}

.btn-link {
  &,
  &.btn {
    background-color: transparent;
    border-color: transparent;
    color: $hs-color-action;
    text-decoration: none;

    &:focus,
    &.focus {
      background-color: transparent;
      border-color: transparent;
      -webkit-box-shadow: 0 0 0 5px rgba($hs-color-action, 0.33);
      box-shadow: 0 0 0 5px rgba($hs-color-action, 0.33);
      color: darken($hs-color-action, 10%);
    }

    &:hover {
      background-color: transparent;
      border-color: transparent;
      color: darken($hs-color-action, 10%);
    }

    &:not(:disabled):not(.disabled):active,
    &:not(:disabled):not(.disabled).active,
    .show > &.dropdown-toggle {
      background-color: transparent;
      border-color: transparent;
      color: darken($hs-color-action, 10%);
    }

    &:not(:disabled):not(.disabled):active:focus,
    &:not(:disabled):not(.disabled).active:focus,
    .show > &.dropdown-toggle:focus {
      -webkit-box-shadow: 0 0 0 5px rgba($hs-color-action, 0.33);
      box-shadow: 0 0 0 5px rgba($hs-color-action, 0.33);
    }
  }
}

// Outline Buttons
// -------------------------

.uix-btn-outline-default {
  &,
  &.btn {
    background-color: transparent;
    border-color: $hs-shade-500;
    border-width: 1px;
    color: $hs-color-active;

    &:focus,
    &.focus {
      background-color: transparent !important;
      border-color: $hs-color-primary;
      -webkit-box-shadow: 0 0 0 5px rgba($hs-color-primary, 0.33);
      box-shadow: 0 0 0 5px rgba($hs-color-primary, 0.33);
      color: $hs-color-active;
      z-index: 1;
    }

    &:hover {
      background-color: transparent !important;
      border-color: $hs-color-primary;
      color: $hs-color-active;
      z-index: 1;
    }

    &:not(:disabled):not(.disabled):active,
    &:not(:disabled):not(.disabled).active,
    .show > &.dropdown-toggle {
      background-color: transparent !important;
      border-color: $hs-color-primary;
      color: $hs-color-active;
    }

    &:not(:disabled):not(.disabled):active:focus,
    &:not(:disabled):not(.disabled).active:focus,
    .show > &.dropdown-toggle:focus {
      -webkit-box-shadow: 0 0 0 5px rgba($hs-color-primary, 0.33);
      box-shadow: 0 0 0 5px rgba($hs-color-primary, 0.33);
    }
  }
}

.TF .themed-outline-button {
  &,
  &.btn {
    background-color: transparent;
    border-color: $hs-shade-500;
    color: $hs-shade-black;

    &:focus,
    &.focus {
      background-color: transparent !important;
      border-color: $hs-color-tf-dark;
      -webkit-box-shadow: 0 0 0 5px rgba($hs-color-tf-dark, 0.33);
      box-shadow: 0 0 0 5px rgba($hs-color-tf-dark, 0.33);
      color: $hs-shade-black;
    }

    &:hover {
      background-color: transparent !important;
      border-color: $hs-color-tf-dark;
      color: $hs-color-tf-dark;
      small {
        color: $hs-color-tf-dark !important;
      }
    }

    &:not(:disabled):not(.disabled):active,
    &:not(:disabled):not(.disabled).active,
    .show > &.dropdown-toggle {
      background-color: transparent !important;
      border-color: $hs-color-tf-dark;
      color: $hs-shade-black;
    }

    &:not(:disabled):not(.disabled):active:focus,
    &:not(:disabled):not(.disabled).active:focus,
    .show > &.dropdown-toggle:focus {
      -webkit-box-shadow: 0 0 0 5px rgba($hs-color-tf-dark, 0.33);
      box-shadow: 0 0 0 5px rgba($hs-color-tf-dark, 0.33);
    }
  }
  small {
    color: $hs-shade-black !important;
  }
}

.btn-outline-primary,
.btn-outline-secondary,
.btn-outline-danger,
.uix-btn-outline-error,
.btn-outline-success,
.btn-outline-warning,
.btn-outline-info,
.btn-outline-light,
.btn-outline-dark {
  &,
  &.btn {
    border-width: 2px;
  }
}

.btn-outline-primary {
  &,
  &.btn {
    background-color: transparent;
    border-color: $hs-color-navy-blue;
    color: $hs-color-navy-blue;

    &:focus,
    &.focus {
      background-color: $hs-color-navy-blue !important;
      border-color: $hs-color-navy-blue;
      -webkit-box-shadow: 0 0 0 5px rgba($hs-color-navy-blue, 0.33);
      box-shadow: 0 0 0 5px rgba($hs-color-navy-blue, 0.33);
      color: $hs-shade-white;
    }

    &:hover {
      background-color: $hs-color-navy-blue !important;
      border-color: $hs-color-navy-blue;
      color: $hs-shade-white;
    }

    &:not(:disabled):not(.disabled):active,
    &:not(:disabled):not(.disabled).active,
    .show > &.dropdown-toggle {
      background-color: darken($hs-color-primary, 2.5%);
      border-color: darken($hs-color-primary, 2.5%);
      color: $hs-shade-white;

      &:focus,
      &.focus {
        -webkit-box-shadow: 0 0 0 5px rgba($hs-color-primary, 0.33);
        box-shadow: 0 0 0 5px rgba($hs-color-primary, 0.33);
      }
    }

    &:focus,
    &.focus,
    &:not(:disabled):not(.disabled):active:focus,
    &:not(:disabled):not(.disabled).active:focus,
    .show > &.dropdown-toggle:focus {
      -webkit-box-shadow: 0 0 0 5px rgba($hs-color-primary, 0.25);
      box-shadow: 0 0 0 5px rgba($hs-color-primary, 0.25);
    }
  }
}

.btn-outline-secondary {
  &,
  &.btn {
    background-color: transparent;
    border-color: $hs-color-secondary; // darken($hs-color-secondary, 5%);
    color: $hs-color-active;

    &:focus,
    &.focus {
      background-color: darken($hs-color-secondary, 10%) !important;
      border-color: darken($hs-color-secondary, 10%);
      -webkit-box-shadow: 0 0 0 5px rgba($hs-color-secondary, 0.66);
      box-shadow: 0 0 0 5px rgba($hs-color-secondary, 0.66);
      color: $hs-color-active;
    }

    &:hover {
      background-color: darken($hs-color-secondary, 10%) !important;
      border-color: darken($hs-color-secondary, 10%);
      color: $hs-color-active;
    }

    &:not(:disabled):not(.disabled):active,
    &:not(:disabled):not(.disabled).active,
    .show > &.dropdown-toggle {
      background-color: darken($hs-color-secondary, 12.5%) !important;
      border-color: darken($hs-color-secondary, 12.5%);
      color: $hs-color-active;
      &.hs-form-art-image {
        background-color: rgba(0, 0, 0, 0) !important;
      }
    }

    &:not(:disabled):not(.disabled):active:focus,
    &:not(:disabled):not(.disabled).active:focus,
    .show > &.dropdown-toggle:focus {
      -webkit-box-shadow: 0 0 0 5px rgba($hs-color-secondary, 0.66);
      box-shadow: 0 0 0 5px rgba($hs-color-secondary, 0.66);
    }
  }
}

.uix-btn-outline-active {
  &,
  &.btn {
    background-color: transparent;
    border-color: $hs-color-active;
    color: $hs-color-active;

    &:focus,
    &.focus {
      background-color: $hs-color-active !important;
      border-color: $hs-color-active;
      -webkit-box-shadow: 0 0 0 5px rgba($hs-color-active, 0.33);
      box-shadow: 0 0 0 5px rgba($hs-color-active, 0.33);
      color: $hs-shade-white;
    }

    &:hover {
      background-color: $hs-color-active !important;
      border-color: $hs-color-active;
      color: $hs-shade-white;
    }

    &:not(:disabled):not(.disabled):active,
    &:not(:disabled):not(.disabled).active,
    .show > &.dropdown-toggle {
      background-color: darken($hs-color-active, 2.5%) !important;
      border-color: darken($hs-color-active, 2.5%);
      color: $hs-shade-white;

      &:focus,
      &.focus {
        -webkit-box-shadow: 0 0 0 5px rgba($hs-color-active, 0.33);
        box-shadow: 0 0 0 5px rgba($hs-color-active, 0.33);
      }
    }

    &:focus,
    &.focus,
    &:not(:disabled):not(.disabled):active:focus,
    &:not(:disabled):not(.disabled).active:focus,
    .show > &.dropdown-toggle:focus {
      -webkit-box-shadow: 0 0 0 5px rgba($hs-color-active, 0.25);
      box-shadow: 0 0 0 5px rgba($hs-color-active, 0.25);
    }
  }
}

.btn-outline-danger,
.uix-btn-outline-error {
  &,
  &.btn {
    background-color: transparent;
    border-color: $hs-color-error;
    color: darken($hs-color-error, 5%);

    &:focus,
    &.focus {
      background-color: $hs-color-error !important;
      border-color: $hs-color-error;
      -webkit-box-shadow: 0 0 0 5px rgba($hs-color-error, 0.33);
      box-shadow: 0 0 0 5px rgba($hs-color-error, 0.33);
      color: $hs-shade-white;
    }

    &:hover {
      background-color: $hs-color-error !important;
      border-color: $hs-color-error;
      color: $hs-shade-white;
    }

    &:not(:disabled):not(.disabled):active,
    &:not(:disabled):not(.disabled).active,
    .show > &.dropdown-toggle {
      background-color: darken($hs-color-error, 2.5%) !important;
      border-color: darken($hs-color-error, 2.5%);
      color: $hs-shade-white;

      &:focus,
      &.focus {
        -webkit-box-shadow: 0 0 0 5px rgba($hs-color-error, 0.33);
        box-shadow: 0 0 0 5px rgba($hs-color-error, 0.33);
      }
    }

    &:focus,
    &.focus,
    &:not(:disabled):not(.disabled):active:focus,
    &:not(:disabled):not(.disabled).active:focus,
    .show > &.dropdown-toggle:focus {
      -webkit-box-shadow: 0 0 0 5px rgba($hs-color-error, 0.25);
      box-shadow: 0 0 0 5px rgba($hs-color-error, 0.25);
    }
  }
}

.btn-outline-success {
  &,
  &.btn {
    background-color: transparent;
    border-color: $hs-color-success;
    color: darken($hs-color-success, 5%);

    &:focus,
    &.focus {
      background-color: $hs-color-success !important;
      border-color: $hs-color-success;
      -webkit-box-shadow: 0 0 0 5px rgba($hs-color-success, 0.33);
      box-shadow: 0 0 0 5px rgba($hs-color-success, 0.33);
      color: $hs-shade-white;
    }

    &:hover {
      background-color: $hs-color-success !important;
      border-color: $hs-color-success;
      color: $hs-shade-white;
    }

    &:not(:disabled):not(.disabled):active,
    &:not(:disabled):not(.disabled).active,
    .show > &.dropdown-toggle {
      background-color: darken($hs-color-success, 2.5%) !important;
      border-color: darken($hs-color-success, 2.5%);
      color: $hs-shade-white;

      &:focus,
      &.focus {
        -webkit-box-shadow: 0 0 0 5px rgba($hs-color-success, 0.33);
        box-shadow: 0 0 0 5px rgba($hs-color-success, 0.33);
      }
    }

    &:focus,
    &.focus,
    &:not(:disabled):not(.disabled):active:focus,
    &:not(:disabled):not(.disabled).active:focus,
    .show > &.dropdown-toggle:focus {
      -webkit-box-shadow: 0 0 0 5px rgba($hs-color-success, 0.25);
      box-shadow: 0 0 0 5px rgba($hs-color-success, 0.25);
    }
  }
}

.btn-outline-warning {
  &,
  &.btn {
    background-color: transparent;
    border-color: $hs-color-warning;
    color: darken($hs-color-warning, 5%);

    &:focus,
    &.focus {
      background-color: $hs-color-warning !important;
      border-color: $hs-color-warning;
      -webkit-box-shadow: 0 0 0 5px rgba($hs-color-warning, 0.33);
      box-shadow: 0 0 0 5px rgba($hs-color-warning, 0.33);
      color: $hs-shade-white;
    }

    &:hover {
      background-color: $hs-color-warning !important;
      border-color: $hs-color-warning;
      color: $hs-shade-white;
    }

    &:not(:disabled):not(.disabled):active,
    &:not(:disabled):not(.disabled).active,
    .show > &.dropdown-toggle {
      background-color: darken($hs-color-warning, 2.5%) !important;
      border-color: darken($hs-color-warning, 2.5%);
      color: $hs-shade-white;

      &:focus,
      &.focus {
        -webkit-box-shadow: 0 0 0 5px rgba($hs-color-warning, 0.33);
        box-shadow: 0 0 0 5px rgba($hs-color-warning, 0.33);
      }
    }

    &:focus,
    &.focus,
    &:not(:disabled):not(.disabled):active:focus,
    &:not(:disabled):not(.disabled).active:focus,
    .show > &.dropdown-toggle:focus {
      -webkit-box-shadow: 0 0 0 5px rgba($hs-color-warning, 0.25);
      box-shadow: 0 0 0 5px rgba($hs-color-warning, 0.25);
    }
  }
}

.btn-outline-info {
  &,
  &.btn {
    background-color: transparent;
    border-color: $hs-color-info;
    color: darken($hs-color-info, 5%);

    &:focus,
    &.focus {
      background-color: $hs-color-info !important;
      border-color: $hs-color-info;
      -webkit-box-shadow: 0 0 0 5px rgba($hs-color-info, 0.33);
      box-shadow: 0 0 0 5px rgba($hs-color-info, 0.33);
      color: $hs-shade-white;
    }

    &:hover {
      background-color: $hs-color-info !important;
      border-color: $hs-color-info;
      color: $hs-shade-white;
    }

    &:not(:disabled):not(.disabled):active,
    &:not(:disabled):not(.disabled).active,
    .show > &.dropdown-toggle {
      background-color: darken($hs-color-info, 2.5%) !important;
      border-color: darken($hs-color-info, 2.5%);
      color: $hs-shade-white;

      &:focus,
      &.focus {
        -webkit-box-shadow: 0 0 0 5px rgba($hs-color-info, 0.33);
        box-shadow: 0 0 0 5px rgba($hs-color-info, 0.33);
      }
    }

    &:focus,
    &.focus,
    &:not(:disabled):not(.disabled):active:focus,
    &:not(:disabled):not(.disabled).active:focus,
    .show > &.dropdown-toggle:focus {
      -webkit-box-shadow: 0 0 0 5px rgba($hs-color-info, 0.25);
      box-shadow: 0 0 0 5px rgba($hs-color-info, 0.25);
    }
  }
}

.btn-outline-light {
  &,
  &.btn {
    background-color: transparent;
    border-color: $hs-shade-white;
    color: $hs-shade-white;

    &:focus,
    &.focus {
      background-color: $hs-shade-white !important;
      border-color: $hs-shade-white;
      -webkit-box-shadow: 0 0 0 5px rgba($hs-shade-white, 0.33);
      box-shadow: 0 0 0 5px rgba($hs-shade-white, 0.33);
      color: $hs-color-dark;
    }

    &:hover {
      background-color: $hs-shade-white !important;
      border-color: $hs-shade-white;
      color: $hs-color-dark;
    }

    &:not(:disabled):not(.disabled):active,
    &:not(:disabled):not(.disabled).active,
    .show > &.dropdown-toggle {
      background-color: darken($hs-shade-white, 2.5%) !important;
      border-color: darken($hs-shade-white, 2.5%);
      color: $hs-color-dark;

      &:focus,
      &.focus {
        -webkit-box-shadow: 0 0 0 5px rgba($hs-shade-white, 0.33);
        box-shadow: 0 0 0 5px rgba($hs-shade-white, 0.33);
      }
    }

    &:focus,
    &.focus,
    &:not(:disabled):not(.disabled):active:focus,
    &:not(:disabled):not(.disabled).active:focus,
    .show > &.dropdown-toggle:focus {
      -webkit-box-shadow: 0 0 0 5px rgba($hs-shade-white, 0.25);
      box-shadow: 0 0 0 5px rgba($hs-shade-white, 0.25);
    }
  }
}

.btn-outline-dark {
  &,
  &.btn {
    background-color: transparent;
    border-color: $hs-color-dark;
    color: $hs-color-dark;

    &:focus,
    &.focus {
      background-color: $hs-color-dark !important;
      border-color: $hs-color-dark;
      -webkit-box-shadow: 0 0 0 5px rgba($hs-color-dark, 0.33);
      box-shadow: 0 0 0 5px rgba($hs-color-dark, 0.33);
      color: $hs-shade-white;
    }

    &:hover {
      background-color: $hs-color-dark !important;
      border-color: $hs-color-dark;
      color: $hs-shade-white;
    }

    &:not(:disabled):not(.disabled):active,
    &:not(:disabled):not(.disabled).active,
    .show > &.dropdown-toggle {
      background-color: darken($hs-color-dark, 2.5%) !important;
      border-color: darken($hs-color-dark, 2.5%);
      color: $hs-shade-white;

      &:focus,
      &.focus {
        -webkit-box-shadow: 0 0 0 5px rgba($hs-color-dark, 0.33);
        box-shadow: 0 0 0 5px rgba($hs-color-dark, 0.33);
      }
    }

    &:focus,
    &.focus,
    &:not(:disabled):not(.disabled):active:focus,
    &:not(:disabled):not(.disabled).active:focus,
    .show > &.dropdown-toggle:focus {
      -webkit-box-shadow: 0 0 0 5px rgba($hs-color-dark, 0.25);
      box-shadow: 0 0 0 5px rgba($hs-color-dark, 0.25);
    }
  }
}

.uix-btn-outline-link {
  &,
  &.btn {
    background-color: transparent;
    border-color: transparent;
    color: $hs-color-action;
    text-decoration: none;

    &:focus,
    &.focus {
      background-color: transparent;
      border-color: transparent;
      -webkit-box-shadow: 0 0 0 5px rgba($hs-color-action, 0.33);
      box-shadow: 0 0 0 5px rgba($hs-color-action, 0.33);
      color: darken($hs-color-action, 10%);
    }

    &:hover {
      background-color: transparent;
      border-color: transparent;
      color: darken($hs-color-action, 10%);
    }

    &:not(:disabled):not(.disabled):active,
    &:not(:disabled):not(.disabled).active,
    .show > &.dropdown-toggle {
      background-color: transparent;
      border-color: transparent;
      color: darken($hs-color-action, 10%);
    }

    &:not(:disabled):not(.disabled):active:focus,
    &:not(:disabled):not(.disabled).active:focus,
    .show > &.dropdown-toggle:focus {
      -webkit-box-shadow: 0 0 0 5px rgba($hs-color-action, 0.33);
      box-shadow: 0 0 0 5px rgba($hs-color-action, 0.33);
    }
  }
}

// Disabled Buttons
// -------------------------

.uix-btn-default,
.btn-primary,
.btn-secondary,
.uix-btn-active,
.btn-danger,
.uix-btn-error,
.btn-success,
.btn-warning,
.btn-info,
.btn-light,
.btn-dark,
.btn-link,
.uix-btn-outline-default,
.btn-outline-primary,
.btn-outline-secondary,
.uix-btn-outline-active,
.btn-outline-danger,
.uix-btn-outline-error,
.btn-outline-success,
.btn-outline-warning,
.btn-outline-info,
.btn-outline-light,
.btn-outline-dark,
.uix-btn-outline-link {
  &.disabled,
  &:disabled {
    &,
    &:focus,
    &:hover {
      -webkit-box-shadow: none !important;
      box-shadow: none !important;
      opacity: 1 !important;
      pointer-events: none !important;
    }
  }
}

.uix-btn-default,
.btn-primary,
.btn-secondary,
.uix-btn-active,
.btn-danger,
.uix-btn-error,
.btn-success,
.btn-warning,
.btn-info,
.btn-light,
.btn-dark,
.uix-btn-outline-default,
.btn-outline-primary,
.btn-outline-secondary,
.uix-btn-outline-active,
.btn-outline-danger,
.uix-btn-outline-error,
.btn-outline-success,
.btn-outline-warning,
.btn-outline-info,
.btn-outline-light,
.btn-outline-dark {
  &.disabled,
  &:disabled {
    &,
    &:focus,
    &:hover {
      border-color: $hs-shade-530 !important;
    }
  }
}

.uix-btn-default,
.btn-primary,
.btn-secondary,
.uix-btn-active,
.btn-danger,
.uix-btn-error,
.btn-success,
.btn-warning,
.btn-info,
.btn-light,
.btn-dark {
  &.disabled,
  &:disabled {
    &,
    &:focus,
    &:hover {
      background-color: $hs-shade-530 !important;
      color: $hs-shade-white !important;
    }
  }
}

.btn-link,
.uix-btn-outline-default,
.btn-outline-primary,
.btn-outline-secondary,
.uix-btn-outline-active,
.btn-outline-danger,
.uix-btn-outline-error,
.btn-outline-success,
.btn-outline-warning,
.btn-outline-info,
.btn-outline-light,
.btn-outline-dark,
.uix-btn-outline-link {
  &.disabled,
  &:disabled {
    &,
    &:focus,
    &:hover {
      color: $hs-shade-560 !important;
    }
  }
}

.uix-btn-outline-default,
.btn-outline-primary,
.btn-outline-secondary,
.uix-btn-outline-active,
.btn-outline-danger,
.uix-btn-outline-error,
.btn-outline-success,
.btn-outline-warning,
.btn-outline-info,
.btn-outline-light,
.btn-outline-dark {
  &.disabled,
  &:disabled {
    &,
    &:focus,
    &:hover {
      background-color: transparent !important;
    }
  }
}

// Button Container
// -------------------------

.uix-button-container {
  align-content: flex-start;
  align-items: stretch;
  display: flex;
  flex-flow: row wrap;
  gap: 0;
  justify-content: flex-start;
  margin: -5px;

  &.centered {
    justify-content: center;
  }

  &.right {
    justify-content: flex-end;
  }

  &.wrap {
    flex-wrap: wrap;
  }
}

// Button Group
// -------------------------

.hs-button-group {
  &,
  &.btn-group {
    margin: 0;
    padding: 0;

    > .btn {
      margin: 0 !important;
    }

    > .btn:not(:first-child),
    > .btn-group:not(:first-child) {
      margin-left: -1px !important;
    }

    > .btn:not(:last-child):not(.dropdown-toggle),
    > .btn-group:not(:last-child) > .btn {
      border-top-right-radius: 0 !important;
      border-bottom-right-radius: 0 !important;
    }

    // The left radius should be 0 if the button is:
    // - the "third or more" child
    // - the second child and the previous element isn't `.btn-check` (making it the first child visually)
    // - part of a btn-group which isn't the first child
    > .btn:nth-child(n + 3),
    > :not(.btn-check) + .btn,
    > .btn-group:not(:first-child) > .btn {
      border-top-left-radius: 0 !important;
      border-bottom-left-radius: 0 !important;
    }
  }
}
