// Lab Forms CSS
// --------------------------------------------------

// Imports
// -------------------------

@import '../../variables';

// Badges
// -------------------------

.hs-form-badge {
  &,
  &.badge {
    border-radius: 0;
    display: none;
    float: left;
    height: 20px;
    margin: 2px 8px 2px 0;
    position: relative;
    width: 20px;

    &.bg-black,
    &.bg-blue,
    &.bg-brown,
    &.bg-clear,
    &.bg-gold,
    &.bg-gray,
    &.bg-green,
    &.bg-orange,
    &.bg-purple,
    &.bg-red,
    &.bg-silver,
    &.bg-white,
    &.bg-yellow,
    &.print-black,
    &.print-blue,
    &.print-brown,
    &.print-clear,
    &.print-gold,
    &.print-gray,
    &.print-green,
    &.print-orange,
    &.print-purple,
    &.print-red,
    &.print-silver,
    &.print-white,
    &.print-yellow {
      &,
      &:empty {
        display: inline-block;
      }
    }

    &.bg-black {
      background-color: $hs-color-tubing-black;
    }

    &.bg-blue {
      background-color: $hs-color-tubing-blue;
    }

    &.bg-brown {
      background-color: $hs-color-tubing-brown;
    }

    &.bg-clear {
      background-color: $hs-color-tubing-clear;
      border: 1px solid $hs-shade-530;
    }

    &.bg-gold {
      background-color: $hs-color-tubing-gold;
    }

    &.bg-gray {
      background-color: $hs-color-tubing-gray;
    }

    &.bg-green {
      background-color: $hs-color-tubing-green;
    }

    &.bg-orange {
      background-color: $hs-color-tubing-orange;
    }

    &.bg-purple {
      background-color: $hs-color-tubing-purple;
    }

    &.bg-red {
      background-color: $hs-color-tubing-red;
    }

    &.bg-silver {
      background-color: $hs-color-tubing-silver;
    }

    &.bg-white {
      background-color: $hs-color-tubing-white;
      border: 1px solid $hs-shade-530;
    }

    &.bg-yellow {
      background-color: $hs-color-tubing-yellow;
    }

    &.print-black {
      background-color: $hs-color-print-black;
    }

    &.print-blue {
      background-color: $hs-color-print-blue;
    }

    &.print-clear {
      background-color: $hs-color-print-clear;
      border: 1px solid $hs-shade-530;
    }

    &.print-gold {
      background-color: $hs-color-print-gold;
    }

    &.print-gray {
      background-color: $hs-color-print-gray;
    }

    &.print-green {
      background-color: $hs-color-print-green;
    }

    &.print-orange {
      background-color: $hs-color-print-orange;
    }

    &.print-purple {
      background-color: $hs-color-print-purple;
    }

    &.print-red {
      background-color: $hs-color-print-red;
    }

    &.print-silver {
      background-color: $hs-color-print-silver;
    }

    &.print-white {
      background-color: $hs-color-print-white;
      border: 1px solid $hs-shade-530;
    }

    &.print-yellow {
      background-color: $hs-color-print-yellow;
    }
  }
}

// Header
// -------------------------

.hs-form-heading {
  border-bottom: 2px solid transparent;
  color: $hs-color-active;
  display: block;
  font-family:
    'Montserrat',
    -apple-system,
    BlinkMacSystemFont,
    'Segoe UI',
    Roboto,
    'Helvetica Neue',
    Arial,
    'Noto Sans',
    sans-serif,
    'Apple Color Emoji',
    'Segoe UI Emoji',
    'Segoe UI Symbol',
    'Noto Color Emoji';
  font-size: 1.1875rem; // 19px
  font-weight: 500;
  margin: 0 0 0 20px;
  min-height: $hs-nav-size;
  padding: 24px 20px 18px 0;
  position: relative;
  text-transform: uppercase;
  z-index: 0;

  &.has-bottom-border {
    border-bottom-color: $hs-shade-150;
  }
}

.TF .hs-form-heading {
  color: $hs-shade-black;
}

.hs-form-heading-link {
  min-height: 0 !important;
  padding: 3px 6px !important;
  position: absolute;
  right: 9px;
  top: 16px;
  z-index: 1;
}

.hs-form-header {
  display: inline-block;
  margin: 0;
  padding: 0;
  position: relative;
  width: 100%;
}

// Quantity Price
// -------------------------

.hs-form-quantity,
.hs-form-price {
  flex-grow: 1;
  white-space: normal;

  > strong,
  > small {
    display: block;
  }

  > strong {
    font-weight: 600;
    margin: 2px 0 0;
  }

  > small {
    font-size: 0.75rem; // 12px
    margin: -2px 0 5px;

    > span {
      white-space: nowrap;
    }
  }
}

.hs-form-quantity {
  padding: 0 5px 0 0;

  > small {
    color: $hs-color-action;
  }

  &.recommended {
    color: $hs-color-action;
  }
}

.hs-form-price {
  color: $hs-color-success-text;
  padding: 0 0 0 5px;
  text-align: right;
}

// Dropdowns
// -------------------------

.hs-form-dropdown-menu {
  &,
  &.dropdown-menu {
    border-color: $hs-shade-500;
    -webkit-box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.25);
    box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.25);
    margin: 0 0 15px;
    max-height: 320px;
    min-width: 100%;
    overflow-y: auto;
    overflow-x: hidden;
    padding: 14px 0;

    scrollbar-base-color: $hs-shade-white;
    scrollbar-3dlight-color: $hs-shade-white;
    scrollbar-highlight-color: $hs-shade-white;
    scrollbar-track-color: $hs-shade-white;
    scrollbar-arrow-color: $hs-shade-150;
    scrollbar-face-color: $hs-shade-150;
    scrollbar-shadow-color: $hs-shade-white;

    &::-webkit-scrollbar {
      height: 9px;
      width: 9px;
      background-color: $hs-shade-white;
    }

    &::-webkit-scrollbar-corner {
      background-color: $hs-shade-white;
    }

    &::-webkit-scrollbar-thumb {
      background-color: $hs-shade-150;

      &:hover {
        background-color: $hs-shade-150;
        border: 0 solid $hs-shade-150;
      }

      &:active {
        background-color: $hs-shade-150;
        border: 0 solid $hs-shade-150;
      }
    }

    .dropdown-item {
      text-decoration: none;
      padding: 6px 15px;

      &.not-compatible {
        opacity: 0.25;
        cursor: not-allowed;
      }
    }

    .dropdown-divider {
      border-top-color: $hs-shade-500;
      margin: 8px;
      opacity: 0.66;
    }

    &.quantity-price {
      .dropdown-item {
        align-content: flex-start;
        align-items: stretch;
        display: flex;
        flex-flow: row nowrap;
        gap: 0;
        justify-content: space-between;
      }
    }
  }
}

.hs-form-dropdown {
  &,
  &.dropdown {
    .hs-form-dropdown-toggle {
      &.btn,
      &.btn-sm,
      &.uix-btn-outline-default,
      &.dropdown-toggle {
        color: $hs-shade-900;
        font-weight: 500;
        margin: 0;
        padding: 6px 15px;
        text-align: left;
        width: 100%;

        &::after {
          color: $hs-shade-540;
          float: right;
          margin: 10px 0 0;
          position: relative;
        }

        &.quantity-price {
          align-content: flex-start;
          align-items: stretch;
          display: flex;
          flex-flow: row nowrap;
          gap: 0;
          justify-content: space-between;

          &::after {
            flex-grow: 0;
            margin: 22px 0 0 10px;
          }
        }
      }
    }
  }
}

// Label
// -------------------------

.hs-form-label {
  &,
  &.form-label {
    color: #212529;
    display: block;
    font-family:
      'Raleway',
      -apple-system,
      BlinkMacSystemFont,
      'Segoe UI',
      Roboto,
      'Helvetica Neue',
      Arial,
      'Noto Sans',
      sans-serif,
      'Apple Color Emoji',
      'Segoe UI Emoji',
      'Segoe UI Symbol',
      'Noto Color Emoji';
    font-size: 1rem; // 16px
    margin: 0 0 3px;
    position: relative;

    // asterisk
    > b {
      color: $hs-color-error;
      display: inline-block;
      font-size: 1.125rem; // 18px
      font-weight: 400;
      margin: -1px 0 -1px 3px;
    }

    // incompatible
    > strong {
      color: $hs-color-error;
      display: none;
      font-size: 0.9375rem; // 15px
      font-weight: 600;
      margin: 0 0 0 4px;
    }

    // optional
    > em {
      color: $hs-shade-580;
      display: block;
      float: right;
      font-style: normal;
      margin: 0 0 0 4px;
      position: relative;
    }

    &.quantity-price {
      > span {
        float: right;
        margin: 0 35px 0 0;
        position: relative;
      }
    }
  }
}

// File
// -------------------------

.hs-form-file-link {
  &,
  &.btn,
  &.btn-sm,
  &.uix-btn-outline-link {
    border-radius: 1px !important;
    font-size: 0.75rem !important; // 12px
    min-height: 0 !important;
    min-width: 0 !important;
    padding: 2px 6px !important;
    position: absolute;
    right: 20px;
    top: 37px;
    z-index: 3;
  }
}

.hs-form-file {
  background-color: $hs-shade-white;
  color: $hs-color-active;
  display: block;
  height: 87px;
  position: relative;
  z-index: 0;

  > svg {
    font-size: 64px;
    height: 64px;
    left: 10px;
    position: absolute;
    top: 10px;
    width: 64px;
  }
  &.art-show {
    width: 100%;
    object-fit: contain;
  }
}

.hs-form-wrapper {
  border: 1px solid $hs-shade-500;
  border-radius: 4px;
  padding: 6px;
}

.slider {
  max-width: 130px;
}

input[type='range'] {
  vertical-align: middle;
  -webkit-appearance: none;
  width: 100%;
}
input[type='range']:focus {
  outline: none;
}
input[type='range']::-webkit-slider-runnable-track {
  width: 100%;
  height: 3px;
  cursor: pointer;
  animate: 0.2s;
  background: #ddd;
  border-radius: 25px;
  border: 1px solid #ebebeb;
}
input[type='range']::-webkit-slider-thumb {
  border: 1px solid #212529;
  height: 15px;
  width: 15px;
  border-radius: 15px;
  background: #212529;
  cursor: pointer;
  -webkit-appearance: none;
  margin-top: -7.5px;
}
input[type='range']:focus::-webkit-slider-runnable-track {
  background: #ddd;
}
input[type='range']::-moz-range-track {
  width: 100%;
  height: 3px;
  cursor: pointer;
  animate: 0.2s;
  background: #ddd;
  border-radius: 25px;
  border: 1px solid #ebebeb;
}
input[type='range']::-moz-range-thumb {
  border: 1px solid #212529;
  height: 15px;
  width: 15px;
  border-radius: 15px;
  background: #212529;
  cursor: pointer;
}
input[type='range']::-ms-track {
  width: 100%;
  height: 3px;
  cursor: pointer;
  animate: 0.2s;
  background: transparent;
  border-color: transparent;
  color: transparent;
}

input[type='range']::-ms-thumb {
  border: 1px solid #212529;
  height: 15px;
  width: 15px;
  border-radius: 15px;
  background: #212529;
  cursor: pointer;
}

// Control
// -------------------------
.hs-form-control {
  &,
  &.form-control {
    border-color: $hs-shade-500;

    &:focus {
      border-color: $hs-color-primary;
      z-index: 1;
    }
  }
}

// Control Group
// -------------------------

.hs-form-control-group {
  display: inline-block;
  margin: 0;
  padding: 10px 20px;
  position: relative;
  width: 100%;

  &.file {
    .hs-form-label {
      z-index: 2;
    }

    .hs-form-control {
      bottom: 0;
      left: 0;
      opacity: 0 !important;
      position: absolute;
      right: 0;
      top: 0;
      z-index: 1;
    }
  }

  &.search {
    margin: 5px 0 0;
  }

  &.has-error {
    .hs-form-label {
      &,
      &.form-label {
        > strong {
          color: $hs-color-error;
          display: inline-block;
          font-size: 0.9375rem; // 15px
          font-weight: 600;
          margin: 0 0 0 4px;
        }
      }
    }

    .hs-form-dropdown {
      &,
      &.dropdown {
        .hs-form-dropdown-toggle {
          &.btn,
          &.btn-sm,
          &.uix-btn-outline-default,
          &.dropdown-toggle {
            //border: 2px solid $hs-color-error !important;
            color: rgba($hs-shade-900, 0.25) !important;
            //margin: -1px !important;

            .hs-form-badge {
              &,
              &.badge {
                opacity: 0.25 !important;
              }
            }

            // &:focus {
            //     box-shadow: 0 0 0 5px rgba($hs-color-error, 33%);
            // }
          }
        }
      }
    }
  }
}

// Rotate
// -------------------------

.hs-form-control-rotate {
  align-content: flex-start;
  align-items: stretch;
  display: flex;
  flex-flow: row nowrap;
  gap: 0;
  justify-content: flex-start;
  margin: 0 0 10px;
  padding: 0;
  position: relative;

  > .btn {
    margin: 0;
    padding: 5px 14px;

    > svg {
      &,
      &:not(:root) {
        margin: 0 !important;
      }
    }

    &:first-child {
      margin-right: -1px !important;
      border-top-right-radius: 0 !important;
      border-bottom-right-radius: 0 !important;
    }

    &:last-child {
      margin-left: -1px !important;
      border-top-left-radius: 0 !important;
      border-bottom-left-radius: 0 !important;
    }
  }

  .hs-form-control {
    &,
    &.form-control {
      border-radius: 0 !important;
      width: 66px;
    }
  }
}

// Row
// -------------------------

.hs-form-control-row {
  align-content: flex-start;
  align-items: stretch;
  display: flex;
  flex-flow: row nowrap;
  gap: 0;
  justify-content: flex-start;
  padding: 10px;
  position: relative;
  width: 100%;

  &.invert-center-flip {
    max-width: 600px;
  }
  .hs-form-control-group {
    padding: 0 10px;
    //width: auto;

    .hs-button-group {
      .btn {
        padding: 5px 14px;

        > svg {
          margin-right: 0;
        }
      }
    }
  }

  &.custom-quantity {
    padding-right: 45px;
    padding-top: 0;

    .hs-form-control-group {
      flex-grow: 0;

      .hs-form-control {
        max-width: 100px;
      }

      &.hs-form-price {
        flex-grow: 1;
      }
    }
  }
}

// Options
// -------------------------

.hs-form-options-row {
  align-content: stretch;
  align-items: stretch;
  display: flex;
  flex-flow: row nowrap;
  gap: 0;
  justify-content: flex-start;
  min-width: 100%;
  position: relative;

  .btn {
    flex-grow: 1;

    > svg {
      &,
      &:not(:root) {
        height: 48px;
        margin: 0;
        width: 48px;

        &.hs-svg-letter-t {
          height: 32px;
          margin: 8px 0;
          width: 32px;
        }
      }
    }

    > span,
    > small {
      display: block;
    }

    > small {
      color: $hs-color-navy-blue;
      font-size: 0.75rem;
      margin: 30px 0 -15px;
      position: relative;
      text-transform: uppercase;
    }

    &.uix-btn-outline-default {
      &:focus,
      &.focus {
        border-color: $hs-color-action;
        -webkit-box-shadow: 0 0 0 5px rgba($hs-color-action, 0.33);
        box-shadow: 0 0 0 5px rgba($hs-color-action, 0.33);
        color: $hs-color-action;
      }

      &:hover {
        border-color: $hs-color-action;
        color: $hs-color-action;
      }

      &:not(:disabled):not(.disabled):active,
      &:not(:disabled):not(.disabled).active,
      .show > &.dropdown-toggle {
        border-color: $hs-color-action;
        color: $hs-color-action;
      }
    }
  }

  &.two-buttons {
    .btn {
      min-height: 128px;
    }
  }

  &.one-button {
    .btn {
      border-style: dashed;
      min-height: 210px;
      // minus 10 for the margin since this was stretching the parent element
      min-width: calc(100% - 10px);
    }
  }
  label {
    margin: 5px 20px;

    &.advisory,
    &.tubing,
    &.print {
      color: rgba($hs-color-primary, 0.75);
      font-size: 0.6875rem; // 11px
      text-transform: uppercase;
    }
  }
  .advisory {
    position: absolute;
    top: 100%;
  }
}

.hs-form-options {
  align-content: flex-start;
  align-items: flex-start;
  display: flex;
  flex-flow: column nowrap;
  gap: 0;
  justify-content: flex-start;
  margin: 0;
  padding: 0 15px 15px;
  position: relative;
  width: 100%;
}

// Art List
// -------------------------

.hs-form-art-item {
  display: block;
  margin: 0;
  max-width: 33.33%;
  min-width: 33.33%;
  padding: 5px;
  position: relative;
  max-height: 100px;
  > .hs-art-item-container {
    margin: auto !important;
    min-height: 96px;
    max-height: 96px;
    max-width: 96px;
    padding: 0px;
    position: relative;
    width: 100%;
    z-index: 0;
    > .hs-form-art-clear {
      &,
      &.btn,
      &.btn-outline-secondary {
        background-color: $hs-shade-white;
        color: $hs-color-error;
        height: 28px;
        margin: 0;
        min-height: 28px;
        min-width: 28px;
        padding: 0;
        position: absolute;
        right: -6px;
        top: -6px;
        width: 28px;
        z-index: 1;
        max-height: 100%;
        max-width: 100%;
        > svg {
          &,
          &:not(:root) {
            margin-right: 0 !important;
          }
        }
        > img {
          max-height: 100%;
          max-width: 100%;
        }

        &:focus,
        &.focus {
          background-color: darken($hs-color-error, 10%) !important;
          border-color: darken($hs-color-error, 10%);
          -webkit-box-shadow: 0 0 0 3px rgba($hs-color-error, 0.33);
          box-shadow: 0 0 0 3px rgba($hs-color-error, 0.33);
          color: $hs-shade-white;
        }

        &:hover {
          background-color: darken($hs-color-error, 10%) !important;
          border-color: darken($hs-color-error, 10%);
          color: $hs-shade-white;
        }

        &:not(:disabled):not(.disabled):active,
        &:not(:disabled):not(.disabled).active,
        .show > &.dropdown-toggle {
          background-color: darken($hs-color-error, 12.5%) !important;
          border-color: darken($hs-color-error, 12.5%);
          color: $hs-shade-white;
        }

        &:not(:disabled):not(.disabled):active:focus,
        &:not(:disabled):not(.disabled).active:focus,
        .show > &.dropdown-toggle:focus {
          -webkit-box-shadow: 0 0 0 3px rgba($hs-color-error, 0.33);
          box-shadow: 0 0 0 3px rgba($hs-color-error, 0.33);
        }
      }
    }

    > .hs-form-art-image {
      margin: 0 !important;
      max-height: 96px;
      height: 92px; // max-height - padding
      max-width: 96px;
      padding: 4px;
      position: relative;
      width: 100%;
      z-index: 0;

      &.recent-artwork {
        border-color: #8bb8d1 !important;
        border-width: 3px !important;
      }

      &:hover {
        background-color: rgba(0, 0, 0, 0) !important;
        border-width: 4px !important;
      }

      &:focus,
      &.focus {
        background-color: rgba(0, 0, 0, 0) !important;
        border-color: #0a3357 !important;
        border-width: 4px !important;
        box-shadow: 0 0 0 0px;
      }

      > svg {
        &,
        &:not(:root) {
          font-size: 48px;
          height: 48px;
          margin-right: 0 !important;
          width: 48px;
        }
      }
      > img {
        max-height: 80px;
        max-width: 100%;
        object-fit: contain;
        padding: 0px;
        margin: 0px;
      }
    }
  }
}

.stock-art {
  opacity: 80%;
}

.hs-form-art-list {
  align-content: flex-start;
  align-items: stretch;
  display: flex;
  flex-flow: row wrap;
  gap: 0;
  justify-content: flex-start;
  margin: 0 0 -20px;
  padding: 15px;
  position: relative;
}

// Body
// -------------------------

.hs-form-body {
  display: inline-block;
  margin: 0;
  min-height: 550px;
  padding: 5px 0 30px;
  position: relative;
  width: 100%;

  &.set-overflow {
    max-height: 550px;
    overflow-y: auto;
    overflow-x: hidden;
    scrollbar-base-color: $hs-shade-white;
    scrollbar-3dlight-color: $hs-shade-white;
    scrollbar-highlight-color: $hs-shade-white;
    scrollbar-track-color: $hs-shade-white;
    scrollbar-arrow-color: $hs-shade-150;
    scrollbar-face-color: $hs-shade-150;
    scrollbar-shadow-color: $hs-shade-white;

    &::-webkit-scrollbar {
      height: 9px;
      width: 9px;
      background-color: $hs-shade-white;
    }

    &::-webkit-scrollbar-corner {
      background-color: $hs-shade-white;
    }

    &::-webkit-scrollbar-thumb {
      background-color: $hs-shade-150;

      &:hover {
        background-color: $hs-shade-150;
        border: 0 solid $hs-shade-150;
      }

      &:active {
        background-color: $hs-shade-150;
        border: 0 solid $hs-shade-150;
      }
    }
  }
}

// Footer
// -------------------------

.hs-form-footer {
  border-top: 4px solid $hs-shade-150;
  margin: 0;
  padding: 10px 15px 15px;
  position: relative;
  white-space: nowrap;
  width: 100%;
}

.TF .hs-form-footer {
  .btn-primary {
    background-color: $hs-shade-black;
    border-color: $hs-shade-black;
    &:hover,
    &:active,
    &:focus {
      background-color: $hs-color-tf-dark !important;
      border-color: $hs-color-tf-dark !important;
    }
  }

  .btn-outline-primary.btn {
    background-color: $hs-color-tf-light-grey;
    border-color: $hs-color-tf-light-grey;
    color: $hs-shade-black;

    &:hover,
    &:focus {
      background-color: $hs-color-tf-dark !important;
      border-color: $hs-color-tf-dark !important;
      color: $hs-shade-white;
    }
    &:not(:disabled):not(.disabled):active {
      background-color: $hs-color-tf-dark !important;
    }
  }
}

// Container
// -------------------------

.hs-form-container {
  align-content: flex-start;
  align-items: stretch;
  display: flex;
  flex-flow: column nowrap;
  gap: 0;
  justify-content: flex-start;
  margin: 0;
  padding: 0;
  position: relative;
  width: 100%;
}

// Form
// -------------------------

.hs-form {
  align-content: flex-start;
  align-items: stretch;
  display: flex;
  flex-flow: column nowrap;
  gap: 0;
  justify-content: flex-start;
  margin: 0 auto;
  padding: 0;
  position: relative;
  text-align: left;
  width: 100%;
}

// Forms
// -------------------------

.hs-forms {
  background-color: $hs-color-form-bg;
  border-right: 2px solid #eee;
  flex-grow: 1;
  margin: 0;
  max-width: 352px;
  min-width: 352px;
  padding: 0;
  position: relative;
  text-align: left;
}

// Media Queries
// -------------------------

// X-Large Devices (large desktops, less than 1400px)
// @media (max-width: 1399.98px) {

// }

// Large Devices (desktops, less than 1200px)
@media (max-width: 1199.98px) {
  .hs-forms {
    border-right-width: 0;
    max-width: 100%;
    min-width: 100%;
    order: 3;

    .hs-form {
      max-width: 100%;
      min-width: 100%;

      .hs-form-container {
        max-width: 352px;
        margin: 0 auto;
      }
    }
  }
}

// Medium Devices (tablets, less than 992px)
// @media (max-width: 991.98px) {

// }

// Small Devices (landscape phones, less than 768px)
// @media (max-width: 767.98px) {

// }

// X-Small Devices (portrait phones, less than 576px)
// @media (max-width: 575.98px) {

// }
