// Pages CSS
// --------------------------------------------------

// Imports
// -------------------------

@import '../../variables';

// Layout
// -------------------------

.hs-page-container {
  &,
  &.container {
    text-align: left;
  }
}

// Page
// -------------------------

.hs-page {
  display: inline-block;
  margin: 0;
  padding: 0;
  position: relative;
  text-align: center;
  width: 100%;
}
