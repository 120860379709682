// Lab Canvas CSS
// ----------------------------------------

// Imports
// -------------------------

@import '../../variables';

// Labels
// -------------------------

.hs-canvas-label {
  margin: 5px;

  &.advisory,
  &.tubing,
  &.print {
    color: $hs-color-navy-blue;
    font-size: 0.6875rem; // 11px
    text-transform: uppercase;
  }

  &.tubing,
  &.advisory,
  &.disclaimer {
    margin-right: 15px;
  }

  &.print {
    margin-left: 15px;
    white-space: nowrap;
  }

  &.disclaimer {
    font-size: 0.9375rem; // 15px
    white-space: normal;

    > a {
      border-radius: 4px;
    }
  }
}

.TF .hs-canvas-label {
  &.print,
  &.tubing {
    color: $hs-color-tf-dark !important;
    font-size: 0.6875rem; // 11px
    text-transform: uppercase;
  }
}

// Ruler
// -------------------------

.hs-canvas-ruler-label {
  color: $hs-canvas-ruler-setting-bg;
  display: inline-block;
  font-size: 0.875rem; // 12px
  font-weight: 600;
  height: 21px;
  overflow: hidden;
  position: absolute;
  text-align: center;
  width: 36px;

  &::after {
    background-color: $hs-canvas-ruler-setting-bg;
    content: ' ';
    position: absolute;
  }
}

.hs-canvas-ruler-marker {
  background-color: $hs-canvas-ruler-setting-bg;
  display: block;
  position: absolute;
}

.hs-canvas-ruler-markers {
  display: block;
  position: absolute;
  z-index: 0;

  > div {
    background-color: $hs-canvas-ruler-bg;
    position: absolute;
  }
}

.hs-canvas-ruler-setting {
  background-color: $hs-canvas-ruler-setting-bg;
  display: block;
  position: absolute;
  text-align: center;
  z-index: 1;

  &::before,
  &::after {
    background-color: $hs-color-designer-bg;
    content: ' ';
    height: 3px;
    position: absolute;
    width: 3px;
  }
}

.hs-canvas-ruler {
  &.diameter,
  &.length,
  &.shrink-ratio {
    background-color: $hs-canvas-ruler-bg;
    position: absolute;
    z-index: 0;
  }

  &.diameter {
    bottom: 0;
    left: -62px;
    top: 0;
    width: 2px;

    .hs-canvas-ruler-markers {
      bottom: 0;
      left: 4px;
      top: 0;
      width: 8px;

      > div {
        height: 2px;
        width: 8px;

        &:first-child {
          top: 0;
        }

        &:nth-child(2) {
          top: 20px;
        }

        &:nth-child(3) {
          top: 40px;
        }

        &:nth-child(4) {
          top: 60px;
        }

        &:nth-child(5) {
          bottom: 60px;
        }

        &:nth-child(6) {
          bottom: 40px;
        }

        &:nth-child(7) {
          bottom: 20px;
        }

        &:last-child {
          bottom: 0;
        }
      }
    }

    .hs-canvas-ruler-setting {
      border-right: 2px solid $hs-color-designer-bg;
      bottom: 0;
      left: 0;
      top: 0;
      width: 6px;

      &::before,
      &::after {
        left: 0;
      }

      &::before {
        top: -3px;
      }

      &::after {
        bottom: -3px;
      }

      .hs-canvas-ruler-marker {
        height: 2px;
        left: 4px;
        width: 8px;

        &.top {
          top: 0;

          &::after {
            border-top: 2px dashed $hs-canvas-ruler-bg;
            content: ' ';
            height: 0;
            position: absolute;
            left: 11px;
            top: 0;
            width: 290px;
          }
        }

        &.bottom {
          bottom: 0;

          &::after {
            border-bottom: 2px dashed $hs-canvas-ruler-bg;
            bottom: 0;
            content: ' ';
            height: 0;
            position: absolute;
            left: 11px;
            width: 290px;
          }
        }
      }

      .hs-canvas-ruler-label {
        margin: -10.5px 0 0;
        right: 4px;
        text-align: left;
        top: 50%;

        &::after {
          height: 4px;
          right: 0;
          top: 9px;
          width: 12px;
        }
      }
    }
  }

  &.length {
    height: 2px;
    left: 0;
    right: 0;
    top: -42px;

    .hs-canvas-ruler-markers {
      right: 0;
      height: 8px;
      left: 0;
      top: 4px;

      > div {
        height: 8px;
        width: 2px;

        &:first-child {
          left: 0;
        }

        &:nth-child(2) {
          left: 40px;
        }

        &:nth-child(3) {
          left: 80px;
        }

        &:nth-child(4) {
          left: 120px;
        }

        &:nth-child(5) {
          left: 160px;
        }

        &:nth-child(6) {
          left: 200px;
        }

        &:nth-child(7) {
          right: 200px;
        }

        &:nth-child(8) {
          right: 160px;
        }

        &:nth-child(9) {
          right: 120px;
        }

        &:nth-child(10) {
          right: 80px;
        }

        &:nth-child(11) {
          right: 40px;
        }

        &:last-child {
          right: 0;
        }
      }
    }

    .hs-canvas-ruler-setting {
      border-bottom: 2px solid $hs-color-designer-bg;
      height: 6px;
      left: 0;
      right: 0;
      top: 0;

      &::before,
      &::after {
        top: 0;
      }

      &::before {
        left: -3px;
      }

      &::after {
        right: -3px;
      }

      .hs-canvas-ruler-marker {
        height: 8px;
        top: 4px;
        width: 2px;

        &.left {
          left: 0;

          &::after {
            border-left: 2px dashed $hs-canvas-ruler-bg;
            content: ' ';
            height: 110px;
            position: absolute;
            left: 0;
            top: 11px;
            width: 0;
          }
        }

        &.right {
          right: 0;

          &::after {
            border-right: 2px dashed $hs-canvas-ruler-bg;
            content: ' ';
            height: 110px;
            position: absolute;
            right: 0;
            top: 11px;
            width: 0;
          }
        }
      }

      .hs-canvas-ruler-label {
        bottom: 4px;
        height: 36px;
        left: 50%;
        margin: 0 0 0 -18px;
        text-align: center;

        &::after {
          bottom: 0;
          height: 12px;
          left: 16px;
          width: 4px;
        }
      }
    }
  }

  &.shrink-ratio {
    bottom: 40px;
    right: -62px;
    top: 40px;
    width: 2px;

    .hs-canvas-ruler-markers {
      bottom: 0;
      right: 4px;
      top: 0;
      width: 8px;

      > div {
        height: 2px;
        width: 8px;

        &:first-child {
          top: 0;
        }

        &:last-child {
          bottom: 0;
        }
      }
    }

    .hs-canvas-ruler-setting {
      border-left: 2px solid $hs-color-designer-bg;
      bottom: 0;
      right: 0;
      top: 0;
      width: 6px;

      &::before,
      &::after {
        right: 0;
      }

      &::before {
        top: -3px;
      }

      &::after {
        bottom: -3px;
      }

      .hs-canvas-ruler-marker {
        height: 2px;
        right: 4px;
        width: 8px;

        &.top {
          top: 0;

          &::after {
            border-top: 2px dashed $hs-canvas-ruler-bg;
            content: ' ';
            height: 0;
            position: absolute;
            right: 11px;
            top: 0;
            width: 290px;
          }
        }

        &.bottom {
          bottom: 0;

          &::after {
            border-bottom: 2px dashed $hs-canvas-ruler-bg;
            bottom: 0;
            content: ' ';
            height: 0;
            position: absolute;
            right: 11px;
            width: 290px;
          }
        }
      }

      .hs-canvas-ruler-label {
        left: 4px;
        margin: -10.5px 0 0;
        text-align: right;
        top: 50%;

        &::after {
          height: 4px;
          left: 0;
          top: 9px;
          width: 12px;
        }
      }
    }
  }
}

// Tubing
// -------------------------

.hs-tubing {
  display: block;
  margin: 0 -5px;
  min-height: 20px; // 1/4" default diameter
  min-width: 80px; // 1" default length
  padding: 0;
  position: relative;
  z-index: 1;

  &.left,
  &.right {
    min-width: 0;
  }

  &.right {
    min-height: 0;
    z-index: 4;
  }
}

.hs-svg-tubing {
  height: 20px; // default diameter
  margin: 0;
  position: relative;
  width: 10px; // half height
  z-index: 0;

  &.left {
    margin-left: -5px; // minus half width
  }

  &.right {
    margin-right: -5px; // minus half width
    z-index: 2;
  }

  &.right-hole {
    height: 16px; // diameter minus 4px
    margin: 2px -3px;
    width: 7px; // half height minus 1
    z-index: 3;
  }

  &.center {
    z-index: 2;
  }

  &.shrink-left {
    z-index: 3;
  }

  &.shrink-right {
    z-index: 5;
  }

  &.shrink-right-hole {
    z-index: 6;
  }
}

.hs-canvas-tubing {
  align-items: stretch;
  color: $hs-color-designer-bg;
  display: flex;
  flex-flow: row nowrap;
  flex-grow: 0;
  gap: 0;
  justify-content: center;
  margin: 0;
  min-height: 20px; // 20px = 1/4" Min Height (1/8" = 10px)
  min-width: 80px; // 80px = 1" Min Width    (1/4" = 20px)
  padding: 0;
  position: relative;
  z-index: 0;
}

.hs-canvas-tubing-invalid {
  color: $hs-color-error;
  display: none;
  //font-size: 0.9375rem; // 15px
  font-weight: 200;

  > svg {
    margin: 0 6px 0 0;
  }
}

// Tubing Container
// -------------------------

.hs-canvas-tubing-container {
  align-items: center;
  display: flex;
  flex-flow: row nowrap;
  flex-grow: 1;
  gap: 0;
  height: $hs-tubing-max-diameter;
  justify-content: center;
  margin: 0 auto;
  max-height: $hs-tubing-max-diameter;
  max-width: 480px;
  min-height: $hs-tubing-max-diameter;
  padding: 0;
  position: relative;
  z-index: 0;

  // Diameters
  // ---------

  &.diameter-0-1-4 {
    .hs-tubing {
      height: $hs-tubing-diameter-0-1-4;
      margin: 0 -5px;
    }

    .hs-svg-tubing {
      height: $hs-tubing-diameter-0-1-4;
      width: 10px;

      &.left {
        margin-left: -5px; // minus half width
      }

      &.right {
        margin-right: -5px; // minus half width
      }

      &.right-hole {
        height: $hs-tubing-diameter-0-1-4 - 6;
        margin: 3px -1px;
        width: 5px;
      }
    }

    .hs-canvas-ruler {
      &.diameter {
        .hs-canvas-ruler-setting {
          bottom: calc(
            ($hs-tubing-max-diameter - $hs-tubing-diameter-0-1-4) / 2
          );
          top: calc(($hs-tubing-max-diameter - $hs-tubing-diameter-0-1-4) / 2);
        }
      }
    }
  }

  &.diameter-0-3-8 {
    .hs-tubing {
      height: $hs-tubing-diameter-0-3-8;
      margin: 0 -8px;
    }

    .hs-svg-tubing {
      height: $hs-tubing-diameter-0-3-8;
      width: 16px;

      &.left {
        margin-left: -8px;
      }

      &.right {
        margin-right: -8px;
      }

      &.right-hole {
        height: $hs-tubing-diameter-0-3-8 - 8;
        margin: 4px -2px;
        width: 9px;
      }
    }

    .hs-canvas-ruler {
      &.diameter {
        .hs-canvas-ruler-setting {
          bottom: calc(
            ($hs-tubing-max-diameter - $hs-tubing-diameter-0-3-8) / 2
          );
          top: calc(($hs-tubing-max-diameter - $hs-tubing-diameter-0-3-8) / 2);
        }
      }
    }
  }

  &.diameter-0-1-2 {
    .hs-tubing {
      height: $hs-tubing-diameter-0-1-2;
      margin: 0 -9px;
    }

    .hs-svg-tubing {
      height: $hs-tubing-diameter-0-1-2;
      width: 18px;

      &.left {
        margin-left: -9px;
      }

      &.right {
        margin-right: -9px;
      }

      &.right-hole {
        height: $hs-tubing-diameter-0-1-2 - 8;
        margin: 4px -3px;
        width: 11px;
      }
    }

    .hs-canvas-ruler {
      &.diameter {
        .hs-canvas-ruler-setting {
          bottom: calc(
            ($hs-tubing-max-diameter - $hs-tubing-diameter-0-1-2) / 2
          );
          top: calc(($hs-tubing-max-diameter - $hs-tubing-diameter-0-1-2) / 2);
        }
      }
    }
  }

  &.diameter-0-5-8 {
    .hs-tubing {
      height: $hs-tubing-diameter-0-5-8;
      margin: 0 -10px;
    }

    .hs-svg-tubing {
      height: $hs-tubing-diameter-0-5-8;
      width: 20px;

      &.left {
        margin-left: -10px;
      }

      &.right {
        margin-right: -10px;
      }

      &.right-hole {
        height: $hs-tubing-diameter-0-5-8 - 8;
        margin: 4px -4px;
        width: 13px;
      }
    }

    .hs-canvas-ruler {
      &.diameter {
        .hs-canvas-ruler-setting {
          bottom: calc(
            ($hs-tubing-max-diameter - $hs-tubing-diameter-0-5-8) / 2
          );
          top: calc(($hs-tubing-max-diameter - $hs-tubing-diameter-0-5-8) / 2);
        }
      }
    }
  }

  &.diameter-0-3-4 {
    .hs-tubing {
      height: $hs-tubing-diameter-0-3-4;
      margin: 0 -12px;
    }

    .hs-svg-tubing {
      height: $hs-tubing-diameter-0-3-4;
      width: 24px;

      &.left {
        margin-left: -12px;
      }

      &.right {
        margin-right: -12px;
      }

      &.right-hole {
        height: $hs-tubing-diameter-0-3-4 - 10;
        margin: 5px -5px;
        width: 16px;
      }
    }

    .hs-canvas-ruler {
      &.diameter {
        .hs-canvas-ruler-setting {
          bottom: calc(
            ($hs-tubing-max-diameter - $hs-tubing-diameter-0-3-4) / 2
          );
          top: calc(($hs-tubing-max-diameter - $hs-tubing-diameter-0-3-4) / 2);
        }
      }
    }
  }

  &.diameter-1-0-0 {
    .hs-tubing {
      height: $hs-tubing-diameter-1-0-0;
      margin: 0 -14px;
    }

    .hs-svg-tubing {
      height: $hs-tubing-diameter-1-0-0;
      width: 28px;

      &.left {
        margin-left: -14px;
      }

      &.right {
        margin-right: -14px;
      }

      &.right-hole {
        height: $hs-tubing-diameter-1-0-0 - 10;
        margin: 5px -5px;
        width: 20px;
      }
    }

    .hs-canvas-ruler {
      &.diameter {
        .hs-canvas-ruler-setting {
          bottom: calc(
            ($hs-tubing-max-diameter - $hs-tubing-diameter-1-0-0) / 2
          );
          top: calc(($hs-tubing-max-diameter - $hs-tubing-diameter-1-0-0) / 2);
        }
      }
    }
  }

  &.diameter-1-1-4 {
    .hs-tubing {
      height: $hs-tubing-diameter-1-1-4;
      margin: 0 -16px;
    }

    .hs-svg-tubing {
      height: $hs-tubing-diameter-1-1-4;
      width: 32px;

      &.left {
        margin-left: -16px;
      }

      &.right {
        margin-right: -16px;
      }

      &.right-hole {
        height: $hs-tubing-diameter-1-1-4 - 12;
        margin: 6px -6px;
        width: 22px;
      }
    }

    .hs-canvas-ruler {
      &.diameter {
        .hs-canvas-ruler-setting {
          bottom: calc(
            ($hs-tubing-max-diameter - $hs-tubing-diameter-1-1-4) / 2
          );
          top: calc(($hs-tubing-max-diameter - $hs-tubing-diameter-1-1-4) / 2);
        }
      }
    }
  }

  &.diameter-1-1-2 {
    .hs-tubing {
      height: $hs-tubing-diameter-1-1-2;
      margin: 0 -18px;
    }

    .hs-svg-tubing {
      height: $hs-tubing-diameter-1-1-2;
      width: 36px;

      &.left {
        margin-left: -18px;
      }

      &.right {
        margin-right: -18px;
      }

      &.right-hole {
        height: $hs-tubing-diameter-1-1-2 - 14;
        margin: 7px -7px;
        width: 25px;
      }
    }

    .hs-canvas-ruler {
      &.diameter {
        .hs-canvas-ruler-setting {
          bottom: calc(
            ($hs-tubing-max-diameter - $hs-tubing-diameter-1-1-2) / 2
          );
          top: calc(($hs-tubing-max-diameter - $hs-tubing-diameter-1-1-2) / 2);
        }
      }
    }
  }

  &.diameter-2-0-0 {
    .hs-tubing {
      //padding-top: 33.33%; // $hs-tubing-diameter-2-0-0;
      height: $hs-tubing-diameter-2-0-0;
      margin: 0 -22px;
    }

    .hs-svg-tubing {
      height: $hs-tubing-diameter-2-0-0;
      width: 44px;

      &.left {
        margin-left: -22px;
      }

      &.right {
        margin-right: -22px;
      }

      &.right-hole {
        height: $hs-tubing-diameter-2-0-0 - 16;
        margin: 8px -10px;
        width: 32px;
      }
    }

    .hs-canvas-ruler {
      &.diameter {
        .hs-canvas-ruler-setting {
          bottom: calc(
            ($hs-tubing-max-diameter - $hs-tubing-diameter-2-0-0) / 2
          );
          top: calc(($hs-tubing-max-diameter - $hs-tubing-diameter-2-0-0) / 2);
        }
      }
    }
  }

  // Lengths
  // -------

  &.length-1-0-0 {
    .hs-tubing {
      width: 80px; // 1" Length Default

      // 13px = difference in between left and right
      &.left {
        width: 47.5px; // 54px - 6.5px (half of difference)
      }

      &.right {
        width: 19.5px; // 26px - 6.5px (half of difference)
      }
    }

    .hs-canvas-ruler {
      &.length {
        .hs-canvas-ruler-setting {
          left: 200px;
          right: 200px;
        }
      }
    }
  }

  &.length-1-1-4 {
    .hs-tubing {
      width: 100px; // 1 1/4" Length

      // 13px = difference in between left and right
      &.left {
        width: 60.5px; // 67px - 6.5px (half of difference)
      }

      &.right {
        width: 26.5px; // 33px - 6.5px (half of difference)
      }
    }

    .hs-canvas-ruler {
      &.length {
        .hs-canvas-ruler-setting {
          left: 190px;
          right: 190px;
        }
      }
    }
  }

  &.length-1-1-2 {
    .hs-tubing {
      width: 120px; // 1 2/4" Length

      // 13px = difference in between left and right
      &.left {
        width: 73.5px; // 80px - 6.5px (half of difference)
      }

      &.right {
        width: 33.5px; // 40px - 6.5px (half of difference)
      }
    }

    .hs-canvas-ruler {
      &.length {
        .hs-canvas-ruler-setting {
          left: 180px;
          right: 180px;
        }
      }
    }
  }

  &.length-1-3-4 {
    .hs-tubing {
      width: 140px; // 1 3/4" Length

      // 13px = difference in between left and right
      &.left {
        width: 87.5px; // 94px - 6.5px (half of difference)
      }

      &.right {
        width: 39.5px; // 46px - 6.5px (half of difference)
      }
    }

    .hs-canvas-ruler {
      &.length {
        .hs-canvas-ruler-setting {
          left: 170px;
          right: 170px;
        }
      }
    }
  }

  &.length-2-0-0 {
    .hs-tubing {
      width: 160px; // 2" Length

      // 13px = difference in between left and right
      &.left {
        width: 100.5px; // 107px - 6.5px (half of difference)
      }

      &.right {
        width: 46.5px; // 53px - 6.5px (half of difference)
      }
    }

    .hs-canvas-ruler {
      &.length {
        .hs-canvas-ruler-setting {
          left: 160px;
          right: 160px;
        }
      }
    }
  }

  &.length-2-1-4 {
    .hs-tubing {
      width: 180px; // 2 1/4" Length

      // 13px = difference in between left and right
      &.left {
        width: 113.5px; // 120px - 6.5px (half of difference)
      }

      &.right {
        width: 53.5px; // 60px - 6.5px (half of difference)
      }
    }

    .hs-canvas-ruler {
      &.length {
        .hs-canvas-ruler-setting {
          left: 150px;
          right: 150px;
        }
      }
    }
  }

  &.length-2-1-2 {
    .hs-tubing {
      width: 200px; // 2 2/4" Length

      // 13px = difference in between left and right
      &.left {
        width: 127.5px; // 134px - 6.5px (half of difference)
      }

      &.right {
        width: 59.5px; // 66px - 6.5px (half of difference)
      }
    }

    .hs-canvas-ruler {
      &.length {
        .hs-canvas-ruler-setting {
          left: 140px;
          right: 140px;
        }
      }
    }
  }

  &.length-2-3-4 {
    .hs-tubing {
      width: 220px; // 2 3/4" Length

      // 13px = difference in between left and right
      &.left {
        width: 140.5px; // 147px - 6.5px (half of difference)
      }

      &.right {
        width: 66.5px; // 73px - 6.5px (half of difference)
      }
    }

    .hs-canvas-ruler {
      &.length {
        .hs-canvas-ruler-setting {
          left: 130px;
          right: 130px;
        }
      }
    }
  }

  &.length-3-0-0 {
    .hs-tubing {
      width: 240px; // 3" Length

      // 13px = difference in between left and right
      &.left {
        width: 153.5px; // 160px - 6.5px (half of difference)
      }

      &.right {
        width: 73.5px; // 80px - 6.5px (half of difference)
      }
    }

    .hs-canvas-ruler {
      &.length {
        .hs-canvas-ruler-setting {
          left: 120px;
          right: 120px;
        }
      }
    }
  }

  &.length-3-1-4 {
    .hs-tubing {
      width: 260px; // 3 1/4" Length

      // 13px = difference in between left and right
      &.left {
        width: 167.5px; // 174px - 6.5px (half of difference)
      }

      &.right {
        width: 79.5px; // 86px - 6.5px (half of difference)
      }
    }

    .hs-canvas-ruler {
      &.length {
        .hs-canvas-ruler-setting {
          left: 110px;
          right: 110px;
        }
      }
    }
  }

  &.length-3-1-2 {
    .hs-tubing {
      width: 280px; // 3 2/4" Length

      // 13px = difference in between left and right
      &.left {
        width: 180.5px; // 187px - 6.5px (half of difference)
      }

      &.right {
        width: 86.5px; // 93px - 6.5px (half of difference)
      }
    }

    .hs-canvas-ruler {
      &.length {
        .hs-canvas-ruler-setting {
          left: 100px;
          right: 100px;
        }
      }
    }
  }

  &.length-3-3-4 {
    .hs-tubing {
      width: 300px; // 3 3/4" Length

      // 13px = difference in between left and right
      &.left {
        width: 193.5px; // 200px - 6.5px (half of difference)
      }

      &.right {
        width: 93.5px; // 100px - 6.5px (half of difference)
      }
    }

    .hs-canvas-ruler {
      &.length {
        .hs-canvas-ruler-setting {
          left: 90px;
          right: 90px;
        }
      }
    }
  }

  &.length-4-0-0 {
    .hs-tubing {
      width: 320px; // 4" Length

      // 13px = difference in between left and right
      &.left {
        width: 207.5px; // 214px - 6.5px (half of difference)
      }

      &.right {
        width: 99.5px; // 106px - 6.5px (half of difference)
      }
    }

    .hs-canvas-ruler {
      &.length {
        .hs-canvas-ruler-setting {
          left: 80px;
          right: 80px;
        }
      }
    }
  }

  &.length-4-1-4 {
    .hs-tubing {
      width: 340px; // 4 1/4" Length

      // 13px = difference in between left and right
      &.left {
        width: 220.5px; // 227px - 6.5px (half of difference)
      }

      &.right {
        width: 106.5px; // 113px - 6.5px (half of difference)
      }
    }

    .hs-canvas-ruler {
      &.length {
        .hs-canvas-ruler-setting {
          left: 70px;
          right: 70px;
        }
      }
    }
  }

  &.length-4-1-2 {
    .hs-tubing {
      width: 360px; // 4 2/4" Length

      // 13px = difference in between left and right
      &.left {
        width: 233.5px; // 240px - 6.5px (half of difference)
      }

      &.right {
        width: 113.5px; // 120px - 6.5px (half of difference)
      }
    }

    .hs-canvas-ruler {
      &.length {
        .hs-canvas-ruler-setting {
          left: 60px;
          right: 60px;
        }
      }
    }
  }

  &.length-4-3-4 {
    .hs-tubing {
      width: 380px; // 4 3/4" Length

      // 13px = difference in between left and right
      &.left {
        width: 247.5px; // 254px - 6.5px (half of difference)
      }

      &.right {
        width: 119.5px; // 126px - 6.5px (half of difference)
      }
    }

    .hs-canvas-ruler {
      &.length {
        .hs-canvas-ruler-setting {
          left: 50px;
          right: 50px;
        }
      }
    }
  }

  &.length-5-0-0 {
    .hs-tubing {
      width: 400px; // 5" Length

      // 13px = difference in between left and right
      &.left {
        width: 260.5px; // 267px - 6.5px (half of difference)
      }

      &.right {
        width: 126.5px; // 133px - 6.5px (half of difference)
      }
    }

    .hs-canvas-ruler {
      &.length {
        .hs-canvas-ruler-setting {
          left: 40px;
          right: 40px;
        }
      }
    }
  }

  &.length-5-1-4 {
    .hs-tubing {
      width: 420px; // 5 1/4" Length

      // 13px (left and right offset difference)
      &.left {
        width: 273.5px; // 280px - 6.5px (half of difference)
      }

      &.right {
        width: 133.5px; // 140px - 6.5px (half of difference)
      }
    }

    .hs-canvas-ruler {
      &.length {
        .hs-canvas-ruler-setting {
          left: 30px;
          right: 30px;
        }
      }
    }
  }

  &.length-5-1-2 {
    .hs-tubing {
      width: 440px; // 5 2/4" Length

      // 13px (left and right offset difference)
      &.left {
        width: 287.5px; // 294px - 6.5px (half of difference)
      }

      &.right {
        width: 139.5px; // 146px - 6.5px (half of difference)
      }
    }

    .hs-canvas-ruler {
      &.length {
        .hs-canvas-ruler-setting {
          left: 20px;
          right: 20px;
        }
      }
    }
  }

  &.length-5-3-4 {
    .hs-tubing {
      width: 460px; // 5 3/4" Length

      // 13px (left and right offset difference)
      &.left {
        width: 300.5px; // 307px - 6.5px (half of difference)
      }

      &.right {
        width: 146.5px; // 153px - 6.5px (half of difference)
      }
    }

    .hs-canvas-ruler {
      &.length {
        .hs-canvas-ruler-setting {
          left: 10px;
          right: 10px;
        }
      }
    }
  }

  &.length-6-0-0 {
    .hs-tubing {
      width: 480px; // 6" Length

      &.left {
        width: 360px;
      }

      &.right {
        width: 120px;
      }
    }

    .hs-canvas-ruler {
      &.length {
        left: 0;
        right: 0;
      }
    }
  }

  // 2:1 Shrink Ratio
  // ----------------

  &.shrink-ratio-2-1 {
    &.diameter-0-1-4 {
      .hs-tubing.right {
        height: $hs-tubing-diameter-0-1-4-shrink-2-1;
        margin: calc(($hs-tubing-diameter-0-1-4-shrink-2-1 / 2)) -2px 0;
      }

      .hs-svg-tubing {
        &.shrink-left,
        &.shrink-right {
          height: $hs-tubing-diameter-0-1-4-shrink-2-1;
          margin-top: calc(($hs-tubing-diameter-0-1-4-shrink-2-1 / 2));
          width: 4px;
        }

        &.shrink-left {
          margin-left: -4px;
        }

        &.shrink-right {
          margin-right: -2px;
        }

        &.shrink-right-hole {
          height: 6px;
          margin: 7px -1px 0;
          width: 3px;
        }
      }

      .hs-canvas-ruler {
        &.shrink-ratio {
          bottom: calc(
            (($hs-tubing-max-diameter - $hs-tubing-diameter-0-1-4) / 2) +
              ($hs-tubing-diameter-0-1-4-shrink-2-1 / 2)
          );
          top: calc(
            (($hs-tubing-max-diameter - $hs-tubing-diameter-0-1-4) / 2) +
              ($hs-tubing-diameter-0-1-4-shrink-2-1 / 2)
          );

          .hs-canvas-ruler-markers {
            > div {
              &:nth-child(2) {
                top: calc(
                  ($hs-tubing-diameter-0-1-4-shrink-2-1 / 2) -
                    ($hs-tubing-diameter-0-1-4-shrink-3-1 / 2)
                );
              }

              &:nth-child(3) {
                bottom: calc(
                  ($hs-tubing-diameter-0-1-4-shrink-2-1 / 2) -
                    ($hs-tubing-diameter-0-1-4-shrink-3-1 / 2)
                );
              }
            }
          }

          .hs-canvas-ruler-setting {
            bottom: 0;
            top: 0;
          }
        }
      }
    }

    &.diameter-0-3-8 {
      .hs-tubing.right {
        height: $hs-tubing-diameter-0-3-8-shrink-2-1;
        margin: calc(($hs-tubing-diameter-0-3-8-shrink-2-1 / 2)) -4px 0;
      }

      .hs-svg-tubing {
        &.shrink-left,
        &.shrink-right {
          height: $hs-tubing-diameter-0-3-8-shrink-2-1;
          margin-top: calc(($hs-tubing-diameter-0-3-8-shrink-2-1 / 2));
          width: 8px;
        }

        &.shrink-left {
          margin-left: -8px;
        }

        &.shrink-right {
          margin-right: -4px;
        }

        &.shrink-right-hole {
          height: 10px;
          margin: 11px -2px 0;
          width: 4px;
        }
      }

      .hs-canvas-ruler {
        &.shrink-ratio {
          bottom: calc(
            (($hs-tubing-max-diameter - $hs-tubing-diameter-0-3-8) / 2) +
              ($hs-tubing-diameter-0-3-8-shrink-2-1 / 2)
          );
          top: calc(
            (($hs-tubing-max-diameter - $hs-tubing-diameter-0-3-8) / 2) +
              ($hs-tubing-diameter-0-3-8-shrink-2-1 / 2)
          );

          .hs-canvas-ruler-markers {
            > div {
              &:nth-child(2) {
                top: calc(
                  ($hs-tubing-diameter-0-3-8-shrink-2-1 / 2) -
                    ($hs-tubing-diameter-0-3-8-shrink-3-1 / 2)
                );
              }

              &:nth-child(3) {
                bottom: calc(
                  ($hs-tubing-diameter-0-3-8-shrink-2-1 / 2) -
                    ($hs-tubing-diameter-0-3-8-shrink-3-1 / 2)
                );
              }
            }
          }

          .hs-canvas-ruler-setting {
            bottom: 0;
            top: 0;
          }
        }
      }
    }

    &.diameter-0-1-2 {
      .hs-tubing.right {
        height: $hs-tubing-diameter-0-1-2-shrink-2-1;
        margin: calc(($hs-tubing-diameter-0-1-2-shrink-2-1 / 2)) -5px 0;
      }

      .hs-svg-tubing {
        &.shrink-left,
        &.shrink-right {
          height: $hs-tubing-diameter-0-1-2-shrink-2-1;
          margin-top: calc($hs-tubing-diameter-0-1-2-shrink-2-1 / 2);
          width: 10px;
        }

        &.shrink-left {
          margin-left: -10px;
        }

        &.shrink-right {
          margin-right: -5px;
        }

        &.shrink-right-hole {
          height: 12px;
          margin: 14px -2px 0;
          width: 5px;
        }
      }

      .hs-canvas-ruler {
        &.shrink-ratio {
          bottom: calc(
            (($hs-tubing-max-diameter - $hs-tubing-diameter-0-1-2) / 2) +
              ($hs-tubing-diameter-0-1-2-shrink-2-1 / 2)
          );
          top: calc(
            (($hs-tubing-max-diameter - $hs-tubing-diameter-0-1-2) / 2) +
              ($hs-tubing-diameter-0-1-2-shrink-2-1 / 2)
          );

          .hs-canvas-ruler-markers {
            > div {
              &:nth-child(2) {
                top: calc(
                  ($hs-tubing-diameter-0-1-2-shrink-2-1 / 2) -
                    ($hs-tubing-diameter-0-1-2-shrink-3-1 / 2)
                );
              }

              &:nth-child(3) {
                bottom: calc(
                  ($hs-tubing-diameter-0-1-2-shrink-2-1 / 2) -
                    ($hs-tubing-diameter-0-1-2-shrink-3-1 / 2)
                );
              }
            }
          }

          .hs-canvas-ruler-setting {
            bottom: 0;
            top: 0;
          }
        }
      }
    }

    &.diameter-0-5-8 {
      .hs-tubing.right {
        height: $hs-tubing-diameter-0-5-8-shrink-2-1;
        margin: calc($hs-tubing-diameter-0-5-8-shrink-2-1 / 2) -6px 0;
      }

      .hs-svg-tubing {
        &.shrink-left,
        &.shrink-right {
          height: $hs-tubing-diameter-0-5-8-shrink-2-1;
          margin-top: calc($hs-tubing-diameter-0-5-8-shrink-2-1 / 2);
          width: 12px;
        }

        &.shrink-left {
          margin-left: -12px;
        }

        &.shrink-right {
          margin-right: -6px;
        }

        &.shrink-right-hole {
          height: 18px;
          margin: 15px -2px 0;
          width: 5px;
        }
      }

      .hs-canvas-ruler {
        &.shrink-ratio {
          bottom: calc(
            (($hs-tubing-max-diameter - $hs-tubing-diameter-0-5-8) / 2) +
              ($hs-tubing-diameter-0-5-8-shrink-2-1 / 2)
          );
          top: calc(
            (($hs-tubing-max-diameter - $hs-tubing-diameter-0-5-8) / 2) +
              ($hs-tubing-diameter-0-5-8-shrink-2-1 / 2)
          );

          .hs-canvas-ruler-markers {
            > div {
              &:nth-child(2) {
                top: calc(
                  ($hs-tubing-diameter-0-5-8-shrink-2-1 / 2) -
                    ($hs-tubing-diameter-0-5-8-shrink-3-1 / 2)
                );
              }

              &:nth-child(3) {
                bottom: calc(
                  ($hs-tubing-diameter-0-5-8-shrink-2-1 / 2) -
                    ($hs-tubing-diameter-0-5-8-shrink-3-1 / 2)
                );
              }
            }
          }

          .hs-canvas-ruler-setting {
            bottom: 0;
            top: 0;
          }
        }
      }
    }

    &.diameter-0-3-4 {
      .hs-tubing.right {
        height: $hs-tubing-diameter-0-3-4-shrink-2-1;
        margin: calc(($hs-tubing-diameter-0-3-4-shrink-2-1 / 2)) -6px 0;
      }

      .hs-svg-tubing {
        &.shrink-left,
        &.shrink-right {
          height: $hs-tubing-diameter-0-3-4-shrink-2-1;
          margin-top: calc($hs-tubing-diameter-0-3-4-shrink-2-1 / 2);
          width: 12px;
        }

        &.shrink-left {
          margin-left: -12px;
        }

        &.shrink-right {
          margin-right: -6px;
        }

        &.shrink-right-hole {
          height: 20px;
          margin: 20px -3px 0;
          width: 7px;
        }
      }

      .hs-canvas-ruler {
        &.shrink-ratio {
          bottom: calc(
            (($hs-tubing-max-diameter - $hs-tubing-diameter-0-3-4) / 2) +
              ($hs-tubing-diameter-0-3-4-shrink-2-1 / 2)
          );
          top: calc(
            (($hs-tubing-max-diameter - $hs-tubing-diameter-0-3-4) / 2) +
              ($hs-tubing-diameter-0-3-4-shrink-2-1 / 2)
          );

          .hs-canvas-ruler-markers {
            > div {
              &:nth-child(2) {
                top: calc(
                  ($hs-tubing-diameter-0-3-4-shrink-2-1 / 2) -
                    ($hs-tubing-diameter-0-3-4-shrink-3-1 / 2)
                );
              }

              &:nth-child(3) {
                bottom: calc(
                  ($hs-tubing-diameter-0-3-4-shrink-2-1 / 2) -
                    ($hs-tubing-diameter-0-3-4-shrink-3-1 / 2)
                );
              }
            }
          }

          .hs-canvas-ruler-setting {
            bottom: 0;
            top: 0;
          }
        }
      }
    }

    &.diameter-1-0-0 {
      .hs-tubing.right {
        height: $hs-tubing-diameter-1-0-0-shrink-2-1;
        margin: calc(($hs-tubing-diameter-1-0-0-shrink-2-1 / 2)) -7px 0;
      }

      .hs-svg-tubing {
        &.shrink-left,
        &.shrink-right {
          height: $hs-tubing-diameter-1-0-0-shrink-2-1;
          margin-top: calc(($hs-tubing-diameter-1-0-0-shrink-2-1 / 2));
          width: 14px;
        }

        &.shrink-left {
          margin-left: -14px;
        }

        &.shrink-right {
          margin-right: -7px;
        }

        &.shrink-right-hole {
          height: 30px;
          margin: 25px -3px 0;
          width: 8px;
        }
      }

      .hs-canvas-ruler {
        &.shrink-ratio {
          bottom: calc(
            (($hs-tubing-max-diameter - $hs-tubing-diameter-1-0-0) / 2) +
              ($hs-tubing-diameter-1-0-0-shrink-2-1 / 2)
          );
          top: calc(
            (($hs-tubing-max-diameter - $hs-tubing-diameter-1-0-0) / 2) +
              ($hs-tubing-diameter-1-0-0-shrink-2-1 / 2)
          );

          .hs-canvas-ruler-markers {
            > div {
              &:nth-child(2) {
                top: calc(
                  ($hs-tubing-diameter-1-0-0-shrink-2-1 / 2) -
                    ($hs-tubing-diameter-1-0-0-shrink-3-1 / 2)
                );
              }

              &:nth-child(3) {
                bottom: calc(
                  ($hs-tubing-diameter-1-0-0-shrink-2-1 / 2) -
                    ($hs-tubing-diameter-1-0-0-shrink-3-1 / 2)
                );
              }
            }
          }

          .hs-canvas-ruler-setting {
            bottom: 0;
            top: 0;
          }
        }
      }
    }

    &.diameter-1-1-4 {
      .hs-tubing.right {
        height: $hs-tubing-diameter-1-1-4-shrink-2-1;
        margin: calc(($hs-tubing-diameter-1-1-4-shrink-2-1 / 2)) -8px 0;
      }

      .hs-svg-tubing {
        &.shrink-left,
        &.shrink-right {
          height: $hs-tubing-diameter-1-1-4-shrink-2-1;
          margin-top: calc(($hs-tubing-diameter-1-1-4-shrink-2-1 / 2));
          width: 16px;
        }

        &.shrink-left {
          margin-left: -16px;
        }

        &.shrink-right {
          margin-right: -8px;
        }

        &.shrink-right-hole {
          height: 38px;
          margin: 31px -4px 0;
          width: 9px;
        }
      }

      .hs-canvas-ruler {
        &.shrink-ratio {
          bottom: calc(
            (($hs-tubing-max-diameter - $hs-tubing-diameter-1-1-4) / 2) +
              ($hs-tubing-diameter-1-1-4-shrink-2-1 / 2)
          );
          top: calc(
            (($hs-tubing-max-diameter - $hs-tubing-diameter-1-1-4) / 2) +
              ($hs-tubing-diameter-1-1-4-shrink-2-1 / 2)
          );

          .hs-canvas-ruler-markers {
            > div {
              &:nth-child(2) {
                top: calc(
                  ($hs-tubing-diameter-1-1-4-shrink-2-1 / 2) -
                    ($hs-tubing-diameter-1-1-4-shrink-3-1 / 2)
                );
              }

              &:nth-child(3) {
                bottom: calc(
                  ($hs-tubing-diameter-1-1-4-shrink-2-1 / 2) -
                    ($hs-tubing-diameter-1-1-4-shrink-3-1 / 2)
                );
              }
            }
          }

          .hs-canvas-ruler-setting {
            bottom: 0;
            top: 0;
          }
        }
      }
    }

    &.diameter-1-1-2 {
      .hs-tubing.right {
        height: $hs-tubing-diameter-1-1-2-shrink-2-1;
        margin: calc(($hs-tubing-diameter-1-1-2-shrink-2-1 / 2)) -9px 0;
      }

      .hs-svg-tubing {
        &.shrink-left,
        &.shrink-right {
          height: $hs-tubing-diameter-1-1-2-shrink-2-1;
          margin-top: calc(($hs-tubing-diameter-1-1-2-shrink-2-1 / 2));
          width: 18px;
        }

        &.shrink-left {
          margin-left: -18px;
        }

        &.shrink-right {
          margin-right: -9px;
        }

        &.shrink-right-hole {
          height: 46px;
          margin: 38px -4px 0;
          width: 10px;
        }
      }

      .hs-canvas-ruler {
        &.shrink-ratio {
          bottom: calc(
            (($hs-tubing-max-diameter - $hs-tubing-diameter-1-1-2) / 2) +
              ($hs-tubing-diameter-1-1-2-shrink-2-1 / 2)
          );
          top: calc(
            (($hs-tubing-max-diameter - $hs-tubing-diameter-1-1-2) / 2) +
              ($hs-tubing-diameter-1-1-2-shrink-2-1 / 2)
          );

          .hs-canvas-ruler-markers {
            > div {
              &:nth-child(2) {
                top: calc(
                  ($hs-tubing-diameter-1-1-2-shrink-2-1 / 2) -
                    ($hs-tubing-diameter-1-1-2-shrink-3-1 / 2)
                );
              }

              &:nth-child(3) {
                bottom: calc(
                  ($hs-tubing-diameter-1-1-2-shrink-2-1 / 2) -
                    ($hs-tubing-diameter-1-1-2-shrink-3-1 / 2)
                );
              }
            }
          }

          .hs-canvas-ruler-setting {
            bottom: 0;
            top: 0;
          }
        }
      }
    }

    &.diameter-2-0-0 {
      .hs-tubing.right {
        height: $hs-tubing-diameter-2-0-0-shrink-2-1;
        margin: calc(($hs-tubing-diameter-2-0-0-shrink-2-1 / 2)) -11px 0;
      }

      .hs-svg-tubing {
        &.shrink-left,
        &.shrink-right {
          height: $hs-tubing-diameter-2-0-0-shrink-2-1;
          margin-top: calc(($hs-tubing-diameter-2-0-0-shrink-2-1 / 2));
          width: 22px;
        }

        &.shrink-left {
          margin-left: -22px;
        }

        &.shrink-right {
          margin-right: -11px;
        }

        &.shrink-right-hole {
          height: 64px;
          margin: 48px -7px 0;
          width: 16px;
        }
      }

      .hs-canvas-ruler {
        &.shrink-ratio {
          bottom: calc(
            (($hs-tubing-max-diameter - $hs-tubing-diameter-2-0-0) / 2) +
              ($hs-tubing-diameter-2-0-0-shrink-2-1 / 2)
          );
          top: calc(
            (($hs-tubing-max-diameter - $hs-tubing-diameter-2-0-0) / 2) +
              ($hs-tubing-diameter-2-0-0-shrink-2-1 / 2)
          );

          .hs-canvas-ruler-markers {
            > div {
              &:nth-child(2) {
                top: calc(
                  ($hs-tubing-diameter-2-0-0-shrink-2-1 / 2) -
                    ($hs-tubing-diameter-2-0-0-shrink-3-1 / 2)
                );
              }

              &:nth-child(3) {
                bottom: calc(
                  ($hs-tubing-diameter-2-0-0-shrink-2-1 / 2) -
                    ($hs-tubing-diameter-2-0-0-shrink-3-1 / 2)
                );
              }
            }
          }

          .hs-canvas-ruler-setting {
            bottom: 0;
            top: 0;
          }
        }
      }
    }
  }

  // 3:1 Shrink Ratio
  // ----------------

  &.shrink-ratio-3-1 {
    &.diameter-0-1-4 {
      .hs-tubing.right {
        height: $hs-tubing-diameter-0-1-4-shrink-3-1;
        margin: $hs-tubing-diameter-0-1-4-shrink-3-1 -1px 0;
      }

      .hs-svg-tubing {
        &.shrink-left,
        &.shrink-right {
          height: $hs-tubing-diameter-0-1-4-shrink-3-1;
          margin-top: $hs-tubing-diameter-0-1-4-shrink-3-1;
          width: 2px;
        }

        &.shrink-left {
          margin-left: -5px;
        }

        &.shrink-right {
          margin-right: -1px;
        }

        &.shrink-right-hole {
          height: 6px;
          margin: 7px -1px 0;
          width: 2px;
        }
      }

      .hs-canvas-ruler {
        &.shrink-ratio {
          bottom: calc(
            (($hs-tubing-max-diameter - $hs-tubing-diameter-0-1-4) / 2) +
              ($hs-tubing-diameter-0-1-4-shrink-2-1 / 2)
          );
          top: calc(
            (($hs-tubing-max-diameter - $hs-tubing-diameter-0-1-4) / 2) +
              ($hs-tubing-diameter-0-1-4-shrink-2-1 / 2)
          );

          .hs-canvas-ruler-markers {
            > div {
              &:nth-child(2) {
                top: calc(
                  ($hs-tubing-diameter-0-1-4-shrink-2-1 / 2) -
                    ($hs-tubing-diameter-0-1-4-shrink-3-1 / 2)
                );
              }

              &:nth-child(3) {
                bottom: calc(
                  ($hs-tubing-diameter-0-1-4-shrink-2-1 / 2) -
                    ($hs-tubing-diameter-0-1-4-shrink-3-1 / 2)
                );
              }
            }
          }

          .hs-canvas-ruler-setting {
            bottom: calc(
              ($hs-tubing-diameter-0-1-4-shrink-2-1 / 2) -
                ($hs-tubing-diameter-0-1-4-shrink-3-1 / 2)
            );
            top: calc(
              ($hs-tubing-diameter-0-1-4-shrink-2-1 / 2) -
                ($hs-tubing-diameter-0-1-4-shrink-3-1 / 2)
            );
          }
        }
      }
    }

    &.diameter-0-3-8 {
      .hs-tubing.right {
        height: $hs-tubing-diameter-0-3-8-shrink-3-1;
        margin: $hs-tubing-diameter-0-3-8-shrink-3-1 -1px 0;
      }

      .hs-svg-tubing {
        &.shrink-left,
        &.shrink-right {
          height: $hs-tubing-diameter-0-3-8-shrink-3-1;
          margin-top: $hs-tubing-diameter-0-3-8-shrink-3-1;
          width: 3px;
        }

        &.shrink-left {
          margin-left: -6px;
        }

        &.shrink-right {
          margin-right: -1px;
        }

        &.shrink-right-hole {
          height: 8px;
          margin: 11px -1px 0;
          width: 2px;
        }
      }

      .hs-canvas-ruler {
        &.shrink-ratio {
          bottom: calc(
            (($hs-tubing-max-diameter - $hs-tubing-diameter-0-3-8) / 2) +
              ($hs-tubing-diameter-0-3-8-shrink-2-1 / 2)
          );
          top: calc(
            (($hs-tubing-max-diameter - $hs-tubing-diameter-0-3-8) / 2) +
              ($hs-tubing-diameter-0-3-8-shrink-2-1 / 2)
          );

          .hs-canvas-ruler-markers {
            > div {
              &:nth-child(2) {
                top: calc(
                  ($hs-tubing-diameter-0-3-8-shrink-2-1 / 2) -
                    ($hs-tubing-diameter-0-3-8-shrink-3-1 / 2)
                );
              }

              &:nth-child(3) {
                bottom: calc(
                  ($hs-tubing-diameter-0-3-8-shrink-2-1 / 2) -
                    ($hs-tubing-diameter-0-3-8-shrink-3-1 / 2)
                );
              }
            }
          }

          .hs-canvas-ruler-setting {
            bottom: calc(
              ($hs-tubing-diameter-0-3-8-shrink-2-1 / 2) -
                ($hs-tubing-diameter-0-3-8-shrink-3-1 / 2)
            );
            top: calc(
              ($hs-tubing-diameter-0-3-8-shrink-2-1 / 2) -
                ($hs-tubing-diameter-0-3-8-shrink-3-1 / 2)
            );
          }
        }
      }
    }

    &.diameter-0-1-2 {
      .hs-tubing.right {
        height: $hs-tubing-diameter-0-1-2-shrink-3-1;
        margin: $hs-tubing-diameter-0-1-2-shrink-3-1 -2px 0;
      }

      .hs-svg-tubing {
        &.shrink-left,
        &.shrink-right {
          height: $hs-tubing-diameter-0-1-2-shrink-3-1;
          margin-top: $hs-tubing-diameter-0-1-2-shrink-3-1;
          width: 4px;
        }

        &.shrink-left {
          margin-left: -7px;
        }

        &.shrink-right {
          margin-right: -2px;
        }

        &.shrink-right-hole {
          height: 8px;
          margin: 15.5px -2px 0;
          width: 3px;
        }
      }

      .hs-canvas-ruler {
        &.shrink-ratio {
          bottom: calc(
            (($hs-tubing-max-diameter - $hs-tubing-diameter-0-1-2) / 2) +
              ($hs-tubing-diameter-0-1-2-shrink-2-1 / 2)
          );
          top: calc(
            (($hs-tubing-max-diameter - $hs-tubing-diameter-0-1-2) / 2) +
              ($hs-tubing-diameter-0-1-2-shrink-2-1 / 2)
          );

          .hs-canvas-ruler-markers {
            > div {
              &:nth-child(2) {
                top: calc(
                  ($hs-tubing-diameter-0-1-2-shrink-2-1 / 2) -
                    ($hs-tubing-diameter-0-1-2-shrink-3-1 / 2)
                );
              }

              &:nth-child(3) {
                bottom: calc(
                  ($hs-tubing-diameter-0-1-2-shrink-2-1 / 2) -
                    ($hs-tubing-diameter-0-1-2-shrink-3-1 / 2)
                );
              }
            }
          }

          .hs-canvas-ruler-setting {
            bottom: calc(
              ($hs-tubing-diameter-0-1-2-shrink-2-1 / 2) -
                ($hs-tubing-diameter-0-1-2-shrink-3-1 / 2)
            );
            top: calc(
              ($hs-tubing-diameter-0-1-2-shrink-2-1 / 2) -
                ($hs-tubing-diameter-0-1-2-shrink-3-1 / 2)
            );
          }
        }
      }
    }

    // CURRENTLY NOT AVAILABLE IN 3:1 SHRINK RATIO
    &.diameter-0-5-8 {
      .hs-tubing.right {
        height: $hs-tubing-diameter-0-5-8-shrink-3-1;
        margin: $hs-tubing-diameter-0-5-8-shrink-3-1 -2px 0;
      }

      .hs-svg-tubing {
        &.shrink-left,
        &.shrink-right {
          height: $hs-tubing-diameter-0-5-8-shrink-3-1;
          margin-top: $hs-tubing-diameter-0-5-8-shrink-3-1;
          width: 5px;
        }

        &.shrink-left {
          margin-left: -9px;
        }

        &.shrink-right {
          margin-right: -2px;
        }

        &.shrink-right-hole {
          height: 12px;
          margin: 19px -2px 0;
          width: 4px;
        }
      }

      .hs-canvas-ruler {
        &.shrink-ratio {
          bottom: calc(
            (($hs-tubing-max-diameter - $hs-tubing-diameter-0-5-8) / 2) +
              ($hs-tubing-diameter-0-5-8-shrink-2-1 / 2)
          );
          top: calc(
            (($hs-tubing-max-diameter - $hs-tubing-diameter-0-5-8) / 2) +
              ($hs-tubing-diameter-0-5-8-shrink-2-1 / 2)
          );

          .hs-canvas-ruler-markers {
            > div {
              &:nth-child(2) {
                top: calc(
                  ($hs-tubing-diameter-0-5-8-shrink-2-1 / 2) -
                    ($hs-tubing-diameter-0-5-8-shrink-3-1 / 2)
                );
              }

              &:nth-child(3) {
                bottom: calc(
                  ($hs-tubing-diameter-0-5-8-shrink-2-1 / 2) -
                    ($hs-tubing-diameter-0-5-8-shrink-3-1 / 2)
                );
              }
            }
          }

          .hs-canvas-ruler-setting {
            bottom: calc(
              ($hs-tubing-diameter-0-5-8-shrink-2-1 / 2) -
                ($hs-tubing-diameter-0-5-8-shrink-3-1 / 2)
            );
            top: calc(
              ($hs-tubing-diameter-0-5-8-shrink-2-1 / 2) -
                ($hs-tubing-diameter-0-5-8-shrink-3-1 / 2)
            );
          }
        }
      }
    }

    &.diameter-0-3-4 {
      .hs-tubing.right {
        height: $hs-tubing-diameter-0-3-4-shrink-3-1;
        margin: $hs-tubing-diameter-0-3-4-shrink-3-1 -3px 0;
      }

      .hs-svg-tubing {
        &.shrink-left,
        &.shrink-right {
          height: $hs-tubing-diameter-0-3-4-shrink-3-1;
          margin-top: $hs-tubing-diameter-0-3-4-shrink-3-1;
          width: 6px;
        }

        &.shrink-left {
          margin-left: -9px;
        }

        &.shrink-right {
          margin-right: -3px;
        }

        &.shrink-right-hole {
          height: 14px;
          margin: 23px -2px 0;
          width: 4px;
        }
      }

      .hs-canvas-ruler {
        &.shrink-ratio {
          bottom: calc(
            (($hs-tubing-max-diameter - $hs-tubing-diameter-0-3-4) / 2) +
              ($hs-tubing-diameter-0-3-4-shrink-2-1 / 2)
          );
          top: calc(
            (($hs-tubing-max-diameter - $hs-tubing-diameter-0-3-4) / 2) +
              ($hs-tubing-diameter-0-3-4-shrink-2-1 / 2)
          );

          .hs-canvas-ruler-markers {
            > div {
              &:nth-child(2) {
                top: calc(
                  ($hs-tubing-diameter-0-3-4-shrink-2-1 / 2) -
                    ($hs-tubing-diameter-0-3-4-shrink-3-1 / 2)
                );
              }

              &:nth-child(3) {
                bottom: calc(
                  ($hs-tubing-diameter-0-3-4-shrink-2-1 / 2) -
                    ($hs-tubing-diameter-0-3-4-shrink-3-1 / 2)
                );
              }
            }
          }

          .hs-canvas-ruler-setting {
            bottom: calc(
              ($hs-tubing-diameter-0-3-4-shrink-2-1 / 2) -
                ($hs-tubing-diameter-0-3-4-shrink-3-1 / 2)
            );
            top: calc(
              ($hs-tubing-diameter-0-3-4-shrink-2-1 / 2) -
                ($hs-tubing-diameter-0-3-4-shrink-3-1 / 2)
            );
          }
        }
      }
    }

    &.diameter-1-0-0 {
      .hs-tubing.right {
        height: $hs-tubing-diameter-1-0-0-shrink-3-1;
        margin: $hs-tubing-diameter-1-0-0-shrink-3-1 -4px 0;
      }

      .hs-svg-tubing {
        &.shrink-left,
        &.shrink-right {
          height: $hs-tubing-diameter-1-0-0-shrink-3-1;
          margin-top: $hs-tubing-diameter-1-0-0-shrink-3-1;
          width: 8px;
        }

        &.shrink-left {
          margin-left: -12px;
        }

        &.shrink-right {
          margin-right: -4px;
        }

        &.shrink-right-hole {
          height: 20px;
          margin: 30px -2px 0;
          width: 5px;
        }
      }

      .hs-canvas-ruler {
        &.shrink-ratio {
          bottom: calc(
            (($hs-tubing-max-diameter - $hs-tubing-diameter-1-0-0) / 2) +
              ($hs-tubing-diameter-1-0-0-shrink-2-1 / 2)
          );
          top: calc(
            (($hs-tubing-max-diameter - $hs-tubing-diameter-1-0-0) / 2) +
              ($hs-tubing-diameter-1-0-0-shrink-2-1 / 2)
          );

          .hs-canvas-ruler-markers {
            > div {
              &:nth-child(2) {
                top: calc(
                  ($hs-tubing-diameter-1-0-0-shrink-2-1 / 2) -
                    ($hs-tubing-diameter-1-0-0-shrink-3-1 / 2)
                );
              }

              &:nth-child(3) {
                bottom: calc(
                  ($hs-tubing-diameter-1-0-0-shrink-2-1 / 2) -
                    ($hs-tubing-diameter-1-0-0-shrink-3-1 / 2)
                );
              }
            }
          }

          .hs-canvas-ruler-setting {
            bottom: calc(
              ($hs-tubing-diameter-1-0-0-shrink-2-1 / 2) -
                ($hs-tubing-diameter-1-0-0-shrink-3-1 / 2)
            );
            top: calc(
              ($hs-tubing-diameter-1-0-0-shrink-2-1 / 2) -
                ($hs-tubing-diameter-1-0-0-shrink-3-1 / 2)
            );
          }
        }
      }
    }

    // CURRENTLY NOT AVAILABLE WITH 3:1 SHRINK RATIO
    &.diameter-1-1-4 {
      .hs-tubing.right {
        height: $hs-tubing-diameter-1-1-4-shrink-3-1;
        margin: $hs-tubing-diameter-1-1-4-shrink-3-1 -5px 0;
      }

      .hs-svg-tubing {
        &.shrink-left,
        &.shrink-right {
          height: $hs-tubing-diameter-1-1-4-shrink-3-1;
          margin-top: $hs-tubing-diameter-1-1-4-shrink-3-1;
          width: 10px;
        }

        &.shrink-left {
          margin-left: -15px;
        }

        &.shrink-right {
          margin-right: -5px;
        }

        &.shrink-right-hole {
          height: 30px;
          margin: 35px -2px 0;
          width: 6px;
        }
      }

      .hs-canvas-ruler {
        &.shrink-ratio {
          bottom: calc(
            (($hs-tubing-max-diameter - $hs-tubing-diameter-1-1-4) / 2) +
              ($hs-tubing-diameter-1-1-4-shrink-2-1 / 2)
          );
          top: calc(
            (($hs-tubing-max-diameter - $hs-tubing-diameter-1-1-4) / 2) +
              ($hs-tubing-diameter-1-1-4-shrink-2-1 / 2)
          );

          .hs-canvas-ruler-markers {
            > div {
              &:nth-child(2) {
                top: calc(
                  ($hs-tubing-diameter-1-1-4-shrink-2-1 / 2) -
                    ($hs-tubing-diameter-1-1-4-shrink-3-1 / 2)
                );
              }

              &:nth-child(3) {
                bottom: calc(
                  ($hs-tubing-diameter-1-1-4-shrink-2-1 / 2) -
                    ($hs-tubing-diameter-1-1-4-shrink-3-1 / 2)
                );
              }
            }
          }

          .hs-canvas-ruler-setting {
            bottom: calc(
              ($hs-tubing-diameter-1-1-4-shrink-2-1 / 2) -
                ($hs-tubing-diameter-1-1-4-shrink-3-1 / 2)
            );
            top: calc(
              ($hs-tubing-diameter-1-1-4-shrink-2-1 / 2) -
                ($hs-tubing-diameter-1-1-4-shrink-3-1 / 2)
            );
          }
        }
      }
    }

    &.diameter-1-1-2 {
      .hs-tubing.right {
        height: $hs-tubing-diameter-1-1-2-shrink-3-1;
        margin: $hs-tubing-diameter-1-1-2-shrink-3-1 -6px 0;
      }

      .hs-svg-tubing {
        &.shrink-left,
        &.shrink-right {
          height: $hs-tubing-diameter-1-1-2-shrink-3-1;
          margin-top: $hs-tubing-diameter-1-1-2-shrink-3-1;
          width: 12px;
        }

        &.shrink-left {
          margin-left: -18px;
        }

        &.shrink-right {
          margin-right: -6px;
        }

        &.shrink-right-hole {
          height: 34px;
          margin: 43px -3px 0;
          width: 7px;
        }
      }

      .hs-canvas-ruler {
        &.shrink-ratio {
          bottom: calc(
            (($hs-tubing-max-diameter - $hs-tubing-diameter-1-1-2) / 2) +
              ($hs-tubing-diameter-1-1-2-shrink-2-1 / 2)
          );
          top: calc(
            (($hs-tubing-max-diameter - $hs-tubing-diameter-1-1-2) / 2) +
              ($hs-tubing-diameter-1-1-2-shrink-2-1 / 2)
          );

          .hs-canvas-ruler-markers {
            > div {
              &:nth-child(2) {
                top: calc(
                  ($hs-tubing-diameter-1-1-2-shrink-2-1 / 2) -
                    ($hs-tubing-diameter-1-1-2-shrink-3-1 / 2)
                );
              }

              &:nth-child(3) {
                bottom: calc(
                  ($hs-tubing-diameter-1-1-2-shrink-2-1 / 2) -
                    ($hs-tubing-diameter-1-1-2-shrink-3-1 / 2)
                );
              }
            }
          }

          .hs-canvas-ruler-setting {
            bottom: calc(
              ($hs-tubing-diameter-1-1-2-shrink-2-1 / 2) -
                ($hs-tubing-diameter-1-1-2-shrink-3-1 / 2)
            );
            top: calc(
              ($hs-tubing-diameter-1-1-2-shrink-2-1 / 2) -
                ($hs-tubing-diameter-1-1-2-shrink-3-1 / 2)
            );
          }
        }
      }
    }

    &.diameter-2-0-0 {
      .hs-tubing.right {
        height: $hs-tubing-diameter-2-0-0-shrink-3-1;
        margin: $hs-tubing-diameter-2-0-0-shrink-3-1 -7px 0;
      }

      .hs-svg-tubing {
        &.shrink-left,
        &.shrink-right {
          height: $hs-tubing-diameter-2-0-0-shrink-3-1;
          margin-top: $hs-tubing-diameter-2-0-0-shrink-3-1;
          width: 14px;
        }

        &.shrink-left {
          margin-left: -21px;
        }

        &.shrink-right {
          margin-right: -7px;
        }

        &.shrink-right-hole {
          height: 43px;
          margin: 58.5px -3px 0;
          width: 8px;
        }
      }

      .hs-canvas-ruler {
        &.shrink-ratio {
          bottom: calc(
            (($hs-tubing-max-diameter - $hs-tubing-diameter-2-0-0) / 2) +
              ($hs-tubing-diameter-2-0-0-shrink-2-1 / 2)
          );
          top: calc(
            (($hs-tubing-max-diameter - $hs-tubing-diameter-2-0-0) / 2) +
              ($hs-tubing-diameter-2-0-0-shrink-2-1 / 2)
          );

          .hs-canvas-ruler-markers {
            > div {
              &:nth-child(2) {
                top: calc(
                  ($hs-tubing-diameter-2-0-0-shrink-2-1 / 2) -
                    ($hs-tubing-diameter-2-0-0-shrink-3-1 / 2)
                );
              }

              &:nth-child(3) {
                bottom: calc(
                  ($hs-tubing-diameter-2-0-0-shrink-2-1 / 2) -
                    ($hs-tubing-diameter-2-0-0-shrink-3-1 / 2)
                );
              }
            }
          }

          .hs-canvas-ruler-setting {
            bottom: calc(
              ($hs-tubing-diameter-2-0-0-shrink-2-1 / 2) -
                ($hs-tubing-diameter-2-0-0-shrink-3-1 / 2)
            );
            top: calc(
              ($hs-tubing-diameter-2-0-0-shrink-2-1 / 2) -
                ($hs-tubing-diameter-2-0-0-shrink-3-1 / 2)
            );
          }
        }
      }
    }
  }

  // Colors
  // ------

  &.black {
    .hs-svg-tubing {
      &.left,
      &.shrink-left {
        color: lighten($hs-color-tubing-black, 20%);
      }

      &.right,
      &.center,
      &.shrink-right {
        color: lighten($hs-color-tubing-black, 12%);
      }

      &.right-hole,
      &.shrink-right-hole {
        color: $hs-color-tubing-black;
      }
    }

    .hs-tubing {
      background-color: lighten($hs-color-tubing-black, 20%);
    }
  }

  &.blue {
    .hs-svg-tubing {
      &.left,
      &.shrink-left {
        color: $hs-color-tubing-blue;
      }

      &.right,
      &.center,
      &.shrink-right {
        color: darken($hs-color-tubing-blue, 8%);
      }

      &.right-hole,
      &.shrink-right-hole {
        color: darken($hs-color-tubing-blue, 20%);
      }
    }

    .hs-tubing {
      background-color: $hs-color-tubing-blue;
    }
  }

  &.brown {
    .hs-svg-tubing {
      &.left,
      &.shrink-left {
        color: $hs-color-tubing-brown;
      }

      &.right,
      &.center,
      &.shrink-right {
        color: darken($hs-color-tubing-brown, 6%);
      }

      &.right-hole,
      &.shrink-right-hole {
        color: darken($hs-color-tubing-brown, 18%);
      }
    }

    .hs-tubing {
      background-color: $hs-color-tubing-brown;
    }
  }

  &.clear {
    .hs-svg-tubing {
      &.left,
      &.shrink-left {
        color: darken($hs-color-tubing-clear, 4%);
      }

      &.right,
      &.center,
      &.shrink-right {
        color: darken($hs-color-tubing-clear, 9%);
      }

      &.right-hole,
      &.shrink-right-hole {
        color: darken($hs-color-tubing-clear, 14%);
      }
    }

    .hs-tubing {
      background-color: darken($hs-color-tubing-clear, 4%);
    }
  }

  &.gray {
    .hs-svg-tubing {
      &.left,
      &.shrink-left {
        color: $hs-color-tubing-gray;
      }

      &.right,
      &.center,
      &.shrink-right {
        color: darken($hs-color-tubing-gray, 8%);
      }

      &.right-hole,
      &.shrink-right-hole {
        color: darken($hs-color-tubing-gray, 20%);
      }
    }

    .hs-tubing {
      background-color: $hs-color-tubing-gray;
    }
  }

  &.green {
    .hs-svg-tubing {
      &.left,
      &.shrink-left {
        color: $hs-color-tubing-green;
      }

      &.right,
      &.center,
      &.shrink-right {
        color: darken($hs-color-tubing-green, 6%);
      }

      &.right-hole,
      &.shrink-right-hole {
        color: darken($hs-color-tubing-green, 12%);
      }
    }

    .hs-tubing {
      background-color: $hs-color-tubing-green;
    }
  }

  &.orange {
    .hs-svg-tubing {
      &.left,
      &.shrink-left {
        color: $hs-color-tubing-orange;
      }

      &.right,
      &.center,
      &.shrink-right {
        color: darken($hs-color-tubing-orange, 8%);
      }

      &.right-hole,
      &.shrink-right-hole {
        color: darken($hs-color-tubing-orange, 20%);
      }
    }

    .hs-tubing {
      background-color: $hs-color-tubing-orange;
    }
  }

  &.purple {
    .hs-svg-tubing {
      &.left,
      &.shrink-left {
        color: $hs-color-tubing-purple;
      }

      &.right,
      &.center,
      &.shrink-right {
        color: darken($hs-color-tubing-purple, 8%);
      }

      &.right-hole,
      &.shrink-right-hole {
        color: darken($hs-color-tubing-purple, 20%);
      }
    }

    .hs-tubing {
      background-color: $hs-color-tubing-purple;
    }
  }

  &.red {
    .hs-svg-tubing {
      &.left,
      &.shrink-left {
        color: $hs-color-tubing-red;
      }

      &.right,
      &.center,
      &.shrink-right {
        color: darken($hs-color-tubing-red, 7%);
      }

      &.right-hole,
      &.shrink-right-hole {
        color: darken($hs-color-tubing-red, 20%);
      }
    }

    .hs-tubing {
      background-color: $hs-color-tubing-red;
    }
  }

  &.white {
    .hs-svg-tubing {
      &.left,
      &.shrink-left {
        color: $hs-color-tubing-white;
      }

      &.right,
      &.center,
      &.shrink-right {
        color: darken($hs-color-tubing-white, 5%);
      }

      &.right-hole,
      &.shrink-right-hole {
        color: darken($hs-color-tubing-white, 10%);
      }
    }

    .hs-tubing {
      background-color: $hs-color-tubing-white;
    }
  }

  &.yellow {
    .hs-svg-tubing {
      &.left,
      &.shrink-left {
        color: $hs-color-tubing-yellow;
      }

      &.right,
      &.center,
      &.shrink-right {
        color: darken($hs-color-tubing-yellow, 12%);
      }

      &.right-hole,
      &.shrink-right-hole {
        color: darken($hs-color-tubing-yellow, 20%);
      }
    }

    .hs-tubing {
      background-color: $hs-color-tubing-yellow;
    }
  }

  // Finishes
  // --------

  // &.glossy {
  //   .hs-svg-tubing {
  //     // Default color (same as background)
  //     &.left,
  //     &.shrink-left {
  //       .hs-svg-gradient-0,
  //       .hs-svg-gradient-33,
  //       .hs-svg-gradient-100 {
  //         stop-color: $hs-color-designer-bg;
  //       }
  //     }

  //     // Enforcing gradient colors
  //     &.left {
  //       .hs-svg-inherit {
  //         fill: url(#LeftGradient);
  //       }
  //     }

  //     &.shrink-left {
  //       .hs-svg-inherit {
  //         fill: url(#ShrinkLeftGradient);
  //       }
  //     }
  //   }

  //   // Gradient colors
  //   &.black {
  //     .hs-svg-tubing {
  //       &.left,
  //       &.shrink-left {
  //         .hs-svg-gradient-0 {
  //           stop-color: lighten($hs-color-tubing-black, 45%);
  //         }

  //         .hs-svg-gradient-33 {
  //           stop-color: lighten($hs-color-tubing-black, 20%);
  //         }

  //         .hs-svg-gradient-100 {
  //           stop-color: lighten($hs-color-tubing-black, 20%);
  //         }
  //       }
  //     }

  //     .hs-tubing {
  //       background: lighten($hs-color-tubing-black, 20%);
  //       background: linear-gradient(
  //         0deg,
  //         lighten($hs-color-tubing-black, 20%) 0%,
  //         lighten($hs-color-tubing-black, 20%) 66%,
  //         lighten($hs-color-tubing-black, 45%) 100%
  //       );
  //     }
  //   }

  //   &.blue {
  //     .hs-svg-tubing {
  //       &.left,
  //       &.shrink-left {
  //         .hs-svg-gradient-0 {
  //           stop-color: lighten($hs-color-tubing-blue, 25%);
  //         }

  //         .hs-svg-gradient-33 {
  //           stop-color: $hs-color-tubing-blue;
  //         }

  //         .hs-svg-gradient-100 {
  //           stop-color: $hs-color-tubing-blue;
  //         }
  //       }
  //     }

  //     .hs-tubing {
  //       background: $hs-color-tubing-blue;
  //       background: linear-gradient(
  //         0deg,
  //         $hs-color-tubing-blue 0%,
  //         $hs-color-tubing-blue 66%,
  //         lighten($hs-color-tubing-blue, 25%) 100%
  //       );
  //     }
  //   }

  //   &.brown {
  //     .hs-svg-tubing {
  //       &.left,
  //       &.shrink-left {
  //         .hs-svg-gradient-0 {
  //           stop-color: lighten($hs-color-tubing-brown, 25%);
  //         }

  //         .hs-svg-gradient-33 {
  //           stop-color: $hs-color-tubing-brown;
  //         }

  //         .hs-svg-gradient-100 {
  //           stop-color: $hs-color-tubing-brown;
  //         }
  //       }
  //     }

  //     .hs-tubing {
  //       background: $hs-color-tubing-brown;
  //       background: linear-gradient(
  //         0deg,
  //         $hs-color-tubing-brown 0%,
  //         $hs-color-tubing-brown 66%,
  //         lighten($hs-color-tubing-brown, 25%) 100%
  //       );
  //     }
  //   }

  //   &.clear {
  //     .hs-svg-tubing {
  //       &.left,
  //       &.shrink-left {
  //         .hs-svg-gradient-0 {
  //           stop-color: $hs-color-tubing-white;
  //         }

  //         .hs-svg-gradient-33 {
  //           stop-color: darken($hs-color-tubing-clear, 4%);
  //         }

  //         .hs-svg-gradient-100 {
  //           stop-color: darken($hs-color-tubing-clear, 4%);
  //         }
  //       }
  //     }

  //     .hs-tubing {
  //       background: darken($hs-color-tubing-clear, 4%);
  //       background: linear-gradient(
  //         0deg,
  //         darken($hs-color-tubing-clear, 4%) 0%,
  //         darken($hs-color-tubing-clear, 4%) 66%,
  //         $hs-color-tubing-white 100%
  //       );
  //     }
  //   }

  //   &.gray {
  //     .hs-svg-tubing {
  //       &.left,
  //       &.shrink-left {
  //         .hs-svg-gradient-0 {
  //           stop-color: lighten($hs-color-tubing-gray, 25%);
  //         }

  //         .hs-svg-gradient-33 {
  //           stop-color: $hs-color-tubing-gray;
  //         }

  //         .hs-svg-gradient-100 {
  //           stop-color: $hs-color-tubing-gray;
  //         }
  //       }
  //     }

  //     .hs-tubing {
  //       background: $hs-color-tubing-gray;
  //       background: linear-gradient(
  //         0deg,
  //         $hs-color-tubing-gray 0%,
  //         $hs-color-tubing-gray 66%,
  //         lighten($hs-color-tubing-gray, 25%) 100%
  //       );
  //     }
  //   }

  //   &.green {
  //     .hs-svg-tubing {
  //       &.left,
  //       &.shrink-left {
  //         .hs-svg-gradient-0 {
  //           stop-color: lighten($hs-color-tubing-green, 25%);
  //         }

  //         .hs-svg-gradient-33 {
  //           stop-color: $hs-color-tubing-green;
  //         }

  //         .hs-svg-gradient-100 {
  //           stop-color: $hs-color-tubing-green;
  //         }
  //       }
  //     }

  //     .hs-tubing {
  //       background: $hs-color-tubing-green;
  //       background: linear-gradient(
  //         0deg,
  //         $hs-color-tubing-green 0%,
  //         $hs-color-tubing-green 66%,
  //         lighten($hs-color-tubing-green, 25%) 100%
  //       );
  //     }
  //   }

  //   &.orange {
  //     .hs-svg-tubing {
  //       &.left,
  //       &.shrink-left {
  //         .hs-svg-gradient-0 {
  //           stop-color: lighten($hs-color-tubing-orange, 20%);
  //         }

  //         .hs-svg-gradient-33 {
  //           stop-color: $hs-color-tubing-orange;
  //         }

  //         .hs-svg-gradient-100 {
  //           stop-color: $hs-color-tubing-orange;
  //         }
  //       }
  //     }

  //     .hs-tubing {
  //       background: $hs-color-tubing-orange;
  //       background: linear-gradient(
  //         0deg,
  //         $hs-color-tubing-orange 0%,
  //         $hs-color-tubing-orange 66%,
  //         lighten($hs-color-tubing-orange, 20%) 100%
  //       );
  //     }
  //   }

  //   &.purple {
  //     .hs-svg-tubing {
  //       &.left,
  //       &.shrink-left {
  //         .hs-svg-gradient-0 {
  //           stop-color: lighten($hs-color-tubing-purple, 25%);
  //         }

  //         .hs-svg-gradient-33 {
  //           stop-color: $hs-color-tubing-purple;
  //         }

  //         .hs-svg-gradient-100 {
  //           stop-color: $hs-color-tubing-purple;
  //         }
  //       }
  //     }

  //     .hs-tubing {
  //       background: $hs-color-tubing-purple;
  //       background: linear-gradient(
  //         0deg,
  //         $hs-color-tubing-purple 0%,
  //         $hs-color-tubing-purple 66%,
  //         lighten($hs-color-tubing-purple, 25%) 100%
  //       );
  //     }
  //   }

  //   &.red {
  //     .hs-svg-tubing {
  //       &.left,
  //       &.shrink-left {
  //         .hs-svg-gradient-0 {
  //           stop-color: lighten($hs-color-tubing-red, 25%);
  //         }

  //         .hs-svg-gradient-33 {
  //           stop-color: $hs-color-tubing-red;
  //         }

  //         .hs-svg-gradient-100 {
  //           stop-color: $hs-color-tubing-red;
  //         }
  //       }
  //     }

  //     .hs-tubing {
  //       background: $hs-color-tubing-red;
  //       background: linear-gradient(
  //         0deg,
  //         $hs-color-tubing-red 0%,
  //         $hs-color-tubing-red 66%,
  //         lighten($hs-color-tubing-red, 25%) 100%
  //       );
  //     }
  //   }

  //   &.white {
  //     .hs-svg-tubing {
  //       &.left,
  //       &.shrink-left {
  //         .hs-svg-gradient-0 {
  //           stop-color: darken($hs-color-tubing-white, 4%);
  //         }

  //         .hs-svg-gradient-33 {
  //           stop-color: $hs-color-tubing-white;
  //         }

  //         .hs-svg-gradient-100 {
  //           stop-color: $hs-color-tubing-white;
  //         }
  //       }
  //     }

  //     .hs-tubing {
  //       background: $hs-color-tubing-white;
  //       background: linear-gradient(
  //         0deg,
  //         $hs-color-tubing-white 0%,
  //         $hs-color-tubing-white 66%,
  //         darken($hs-color-tubing-white, 4%) 100%
  //       );
  //     }
  //   }

  //   &.yellow {
  //     .hs-svg-tubing {
  //       &.left,
  //       &.shrink-left {
  //         .hs-svg-gradient-0 {
  //           stop-color: lighten($hs-color-tubing-yellow, 25%);
  //         }

  //         .hs-svg-gradient-33 {
  //           stop-color: $hs-color-tubing-yellow;
  //         }

  //         .hs-svg-gradient-100 {
  //           stop-color: $hs-color-tubing-yellow;
  //         }
  //       }
  //     }

  //     .hs-tubing {
  //       background: $hs-color-tubing-yellow;
  //       background: linear-gradient(
  //         0deg,
  //         $hs-color-tubing-yellow 0%,
  //         $hs-color-tubing-yellow 66%,
  //         lighten($hs-color-tubing-yellow, 25%) 100%
  //       );
  //     }
  //   }
  // }

  // Note: This is default (i.e. no class name is needed)
  // &.standard {
  //     // Adding this class name to the element is not required
  // }

  // Fill
  // ----

  &.fill {
    min-width: 100%; // 796px full desktop width
    padding: 0 40px;

    .hs-canvas-tubing {
      min-width: 100%; // 796px full desktop width

      .hs-tubing {
        min-width: 100%; // 796px full desktop width
      }
    }

    // TODO: ERIC, Set aspect ratio heights to fill width of canvas area

    // &.length-1-0-0 {

    // }

    // &.length-1-1-4 {

    // }

    // &.length-1-1-2 {

    // }

    // &.length-1-3-4 {

    // }

    // &.length-2-0-0 {

    // }

    // &.length-2-1-4 {

    // }

    // &.length-2-1-2 {

    // }

    // &.length-2-3-4 {

    // }

    // &.length-3-0-0 {

    // }

    // &.length-3-1-4 {

    // }

    // &.length-3-1-2 {

    // }

    // &.length-3-3-4 {

    // }

    // &.length-4-0-0 {

    // }

    // &.length-4-1-4 {

    // }

    // &.length-4-1-2 {

    // }

    // &.length-4-3-4 {

    // }

    // &.length-5-0-0 {

    // }

    // &.length-5-1-4 {

    // }

    // &.length-5-1-2 {

    // }

    &.length-5-3-4 {
      &.diameter-0-1-4 {
        .hs-tubing {
          height: 35px; // 20px default
        }

        .hs-svg-tubing {
          height: 35px; // 20px default

          &.right-hole {
            height: 35px - 6; // 14px default
            margin: 3px -2px;
          }
        }
      }

      &.diameter-0-3-8 {
        .hs-tubing {
          height: 52px; // 30px default
        }

        .hs-svg-tubing {
          height: 52px; // 30px default

          &.right-hole {
            height: 52px - 8; // 22px default
            margin: 4px -3px;
          }
        }
      }

      &.diameter-0-1-2 {
        .hs-tubing {
          height: 70px; // 40px default
        }

        .hs-svg-tubing {
          height: 70px; // 40px default

          &.right-hole {
            height: 70px - 8; // 32px default
            margin: 4px -4px;
          }
        }
      }

      &.diameter-0-5-8 {
        .hs-tubing {
          height: 87px; // 50px default
        }

        .hs-svg-tubing {
          height: 87px; // 50px default

          &.right-hole {
            height: 87px - 8; // 42px default
            margin: 4px -5px;
          }
        }
      }

      &.diameter-0-3-4 {
        .hs-tubing {
          height: 104px; // 60px default
        }

        .hs-svg-tubing {
          height: 104px; // 60px default

          &.right-hole {
            height: 104px - 10; // 50px default
            margin: 5px -6px;
          }
        }
      }

      &.diameter-1-0-0 {
        .hs-tubing {
          height: 139px; // 80px default
        }

        .hs-svg-tubing {
          height: 139px; // 80px default

          &.right-hole {
            height: 139px - 10; // 70px default
            margin: 5px -7px;
          }
        }
      }

      &.diameter-1-1-4 {
        .hs-tubing {
          height: 174px; // 100px default
        }

        .hs-svg-tubing {
          height: 174px; // 100px default

          &.right-hole {
            height: 174px - 12; // 88px default
            margin: 6px -8px;
          }
        }
      }

      &.diameter-1-1-2 {
        .hs-tubing {
          height: 208px; // 120px default
        }

        .hs-svg-tubing {
          height: 208px; // 120px default

          &.right-hole {
            height: 208px - 14; // 106px default
            margin: 7px -9px;
          }
        }
      }

      &.diameter-2-0-0 {
        .hs-tubing {
          height: 277px; // 160px default
        }

        .hs-svg-tubing {
          height: 277px; // 160px default

          &.right-hole {
            height: 277px - 16; // 144px default
            margin: 8px -12px;
          }
        }
      }
    }

    &.length-6-0-0 {
      &.diameter-0-1-4 {
        .hs-tubing {
          height: 34px; // 20px default
        }

        .hs-svg-tubing {
          height: 34px; // 20px default

          &.right-hole {
            height: 34px - 6; // 14px default
            margin: 3px -1px;
          }
        }
      }

      &.diameter-0-3-8 {
        .hs-tubing {
          height: 50px; // 30px default
        }

        .hs-svg-tubing {
          height: 50px; // 30px default

          &.right-hole {
            height: 50px - 8; // 22px default
            margin: 4px -3px;
          }
        }
      }

      &.diameter-0-1-2 {
        .hs-tubing {
          height: 67px; // 40px default
        }

        .hs-svg-tubing {
          height: 67px; // 40px default

          &.right-hole {
            height: 67px - 8; // 32px default
            margin: 4px -4px;
          }
        }
      }

      &.diameter-0-5-8 {
        .hs-tubing {
          height: 83px; // 50px default
        }

        .hs-svg-tubing {
          height: 83px; // 50px default

          &.right-hole {
            height: 83px - 8; // 42px default
            margin: 4px -5px;
          }
        }
      }

      &.diameter-0-3-4 {
        .hs-tubing {
          height: 100px; // 60px default
        }

        .hs-svg-tubing {
          height: 100px; // 60px default

          &.right-hole {
            height: 100px - 10; // 50px default
            margin: 5px -7px;
          }
        }
      }

      &.diameter-1-0-0 {
        .hs-tubing {
          height: 133px; // 80px default
        }

        .hs-svg-tubing {
          height: 133px; // 80px default

          &.right-hole {
            height: 133px - 10; // 70px default
            margin: 5px -7px;
          }
        }
      }

      &.diameter-1-1-4 {
        .hs-tubing {
          height: 166px; // 100px default
        }

        .hs-svg-tubing {
          height: 166px; // 100px default

          &.right-hole {
            height: 166px - 12; // 88px default
            margin: 6px -8px;
          }
        }
      }

      &.diameter-1-1-2 {
        .hs-tubing {
          height: 199px; // 120px default
        }

        .hs-svg-tubing {
          height: 199px; // 120px default

          &.right-hole {
            height: 199px - 14; // 106px default
            margin: 7px -9px;
          }
        }
      }

      &.diameter-2-0-0 {
        .hs-tubing {
          height: 266px; // 160px default
        }

        .hs-svg-tubing {
          height: 266px; // 160px default

          &.right-hole {
            height: 266px - 16; // 144px default
            margin: 8px -12px;
          }
        }
      }
    }
  }
}

// Canvas
// -------------------------

.hs-canvas-header,
.hs-canvas-body,
.hs-canvas-footer {
  gap: 0;
  margin: 0;
  min-width: 100%;
  max-width: 100%;
  position: relative;
  min-height: 66.5px;
}

.hs-canvas-header,
.hs-canvas-footer {
  align-content: flex-start;
  align-items: flex-start;
  display: flex;
  flex-flow: row nowrap;
  flex-grow: 0;
  justify-content: space-between;

  &.invalid-tubing {
    display: none;
  }
}

.hs-canvas-header {
  max-height: 77px;
  padding: 20px;
}

.hs-canvas-body {
  align-items: center;
  display: flex;
  flex-flow: row nowrap;
  flex-grow: 3;
  justify-content: center;
  padding: 20px 0;
  height: 100%;

  &.invalid-tubing {
    .hs-canvas-ruler,
    .hs-canvas-tubing {
      display: none;
    }

    .hs-canvas-tubing-invalid {
      display: block;
    }
  }
}

.hs-canvas-footer {
  max-height: 77px;
  min-height: 77px;
  padding: 14px 15px 15px;
}

.hs-canvas {
  align-items: center;
  display: flex;
  flex-flow: column nowrap;
  flex-grow: 3;
  gap: 0;
  justify-content: space-between;
  margin: 0;
  max-width: 100%;
  padding: 0;
  position: relative;
}

.hs-canvas-wrapper {
  width: 100%;
  height: 100%;
  min-height: 200px;
}
// Media Queries
// -------------------------

// X-Large Devices (large desktops, less than 1400px)
// @media (max-width: 1399.98px) {

// }

// Large Devices (desktops, less than 1200px)
@media (max-width: 1199.98px) {
  .hs-canvas {
    border-bottom: 2px solid #eee;
    //border-top: 2px solid #eee;
    margin: 0;
    order: 2;
    overflow: auto;
    padding: 20px 0 0 0;
    text-align: center;

    .hs-canvas-header {
      display: inline-block;
      max-height: 100%;
      max-width: 100%;
      min-width: 0;
      min-height: 66.5px;
      order: 2;
      position: relative;

      .hs-canvas-label {
        display: inline-block;
        flex: none;
        margin: 0 4px 0 0;
        position: relative;
        white-space: normal;
      }
    }

    .hs-canvas-body {
      display: inline-block;
      order: 1;

      .hs-canvas-ruler {
        display: none;
      }

      .hs-canvas-tubing {
        margin: 0 40px;
      }
    }

    .hs-canvas-footer {
      display: none;
    }

    scrollbar-base-color: $hs-color-designer-bg;
    scrollbar-3dlight-color: $hs-color-designer-bg;
    scrollbar-highlight-color: $hs-color-designer-bg;
    scrollbar-track-color: $hs-color-designer-bg;
    scrollbar-arrow-color: $hs-shade-250;
    scrollbar-face-color: $hs-shade-250;
    scrollbar-shadow-color: $hs-color-designer-bg;

    &::-webkit-scrollbar {
      height: 9px;
      width: 9px;
      background-color: $hs-color-designer-bg;
    }

    &::-webkit-scrollbar-corner {
      background-color: $hs-color-designer-bg;
    }

    &::-webkit-scrollbar-thumb {
      background-color: $hs-shade-250;

      &:hover {
        background-color: $hs-shade-250;
        border: 0 solid $hs-shade-250;
      }

      &:active {
        background-color: $hs-shade-250;
        border: 0 solid $hs-shade-250;
      }
    }
  }
}

// Medium Devices (tablets, less than 992px)
// @media (max-width: 991.98px) {

// }

// Small Devices (landscape phones, less than 768px)
@media (max-width: 767.98px) {
}

// X-Small Devices (portrait phones, less than 576px)
// @media (max-width: 575.98px) {

// }
