@import '../../../variables';
@import '../Pages.scss';

.hs-designer {
  background-color: $hs-color-designer-bg;
  border: 2px solid $hs-color-designer-border;
  align-content: stretch;
  align-items: stretch;
  display: flex;
  flex-flow: row nowrap;
  gap: 0;
  justify-content: flex-start;
  margin: 0 auto;
  min-height: 620px;
  padding: 0;
  position: relative;
  width: 100%;
  @media (max-width: 1199.98px) {
    flex-flow: column nowrap;
  }
}

.WC .hs-designer {
  background-color: $hs-color-designer-bg;
  border: 2px solid $hs-color-designer-border;
}

.TF .hs-designer {
  background-color: $hs-color-designer-bg;
  border: 2px solid $hs-color-designer-border2;
}

// Large Devices (desktops, less than 1200px)
@media (max-width: 1199.98px) {
  .hs-designer {
    flex-flow: column nowrap;
  }
}
