// Heat Shrink Variables
// --------------------------------------------------

// Shades
// -------------------------

$hs-shade-white: #fff;
$hs-shade-100: #eff3f7;
$hs-shade-150: #eee;
$hs-shade-200: #e8ebee;
$hs-shade-250: #ddd;
$hs-shade-300: #dae1e6;
$hs-shade-400: #d1d8de;
$hs-shade-500: #ced4da;
$hs-shade-530: #ccc;
$hs-shade-540: #bec6cc;
$hs-shade-560: #bbb;
$hs-shade-570: #aaa;
$hs-shade-580: #9ca4aa;
$hs-shade-590: #999;
$hs-shade-600: #696969;
$hs-shade-700: #545454;
$hs-shade-750: #454545;
$hs-shade-800: #323232;
$hs-shade-900: #212529;
$hs-shade-black: #000;

// Colors
// -------------------------

$hs-color-error: #d9463c;
$hs-color-success: #85b31f;
$hs-color-success-text: #46610f;
$hs-color-warning: #eca737;
$hs-color-info: #8bb8d1;
$hs-color-tf-dark: #696969;
$hs-color-tf-light-grey: #d9d9d9;
$hs-color-primary: #5a83a1;
$hs-color-secondary: #e0e8ef;
$hs-color-action: #337ab7;
$hs-color-navy-blue: #0a3257;
$hs-color-active: $hs-color-navy-blue;
$hs-color-dark: #18263f;

$hs-color-tubing-black: #000;
$hs-color-tubing-blue: #578dbe;
$hs-color-tubing-brown: #936b53;
$hs-color-tubing-clear: #f9f9f9;
$hs-color-tubing-gold: #cfaf4e;
$hs-color-tubing-gray: #8e9089;
$hs-color-tubing-green: #00c65e;
$hs-color-tubing-orange: #ffa06a;
$hs-color-tubing-purple: #8068af;
$hs-color-tubing-red: #b41d3c;
$hs-color-tubing-silver: #bfbfbf;
$hs-color-tubing-white: #fff;
$hs-color-tubing-yellow: #f2c640;

$hs-color-print-black: #000;
$hs-color-print-blue: #10069f;
$hs-color-print-clear: #f9f9f9;
$hs-color-print-gold: #cfaf4e;
$hs-color-print-gray: #8e9089;
$hs-color-print-green: #285c4d;
$hs-color-print-orange: #ffa06a;
$hs-color-print-purple: #6638b6;
$hs-color-print-red: #ce0037;
$hs-color-print-silver: #bfbfbf;
$hs-color-print-white: #fff;
$hs-color-print-yellow: #fedb00;

$hs-color-nav-bg: $hs-color-info;
$hs-color-tf-bg: $hs-color-tf-dark;
$hs-color-form-bg: $hs-shade-white;
$hs-color-designer-bg: #f8f8f8;
$hs-color-designer-border: $hs-color-nav-bg;
$hs-color-designer-border2: $hs-color-tf-dark;

// Nav
// -------------------------

$hs-nav-size: 64px;

// Buttons
// -------------------------

$hs-btn-lg-min-size: 58px;
$hs-btn-md-min-size: 48px;
$hs-btn-sm-min-size: 36px;

// Modal
// -------------------------

$hs-modal-header-bg: #8cb7d0;
$hs-modal-header-bg2: $hs-color-tf-dark;

// Canvas
// -------------------------

$hs-canvas-ruler-bg: $hs-color-navy-blue;
$hs-canvas-ruler-setting-bg: $hs-color-navy-blue;

$hs-tubing-diameter-0-1-4: 20px;
$hs-tubing-diameter-0-3-8: 30px;
$hs-tubing-diameter-0-1-2: 40px;
$hs-tubing-diameter-0-5-8: 50px;
$hs-tubing-diameter-0-3-4: 60px;
$hs-tubing-diameter-1-0-0: 80px;
$hs-tubing-diameter-1-1-4: 100px;
$hs-tubing-diameter-1-1-2: 120px;
$hs-tubing-diameter-2-0-0: 160px;

$hs-tubing-max-diameter: $hs-tubing-diameter-2-0-0;

$hs-tubing-diameter-0-3-8-shrink-2-1: calc($hs-tubing-diameter-0-3-8 / 2);
$hs-tubing-diameter-0-1-2-shrink-2-1: calc($hs-tubing-diameter-0-1-2 / 2);
$hs-tubing-diameter-0-1-4-shrink-2-1: calc($hs-tubing-diameter-0-1-4 / 2);
$hs-tubing-diameter-0-5-8-shrink-2-1: calc($hs-tubing-diameter-0-5-8 / 2);
$hs-tubing-diameter-0-3-4-shrink-2-1: calc($hs-tubing-diameter-0-3-4 / 2);
$hs-tubing-diameter-1-0-0-shrink-2-1: calc($hs-tubing-diameter-1-0-0 / 2);
$hs-tubing-diameter-1-1-4-shrink-2-1: calc($hs-tubing-diameter-1-1-4 / 2);
$hs-tubing-diameter-1-1-2-shrink-2-1: calc($hs-tubing-diameter-1-1-2 / 2);
$hs-tubing-diameter-2-0-0-shrink-2-1: calc($hs-tubing-diameter-2-0-0 / 2);

$hs-tubing-diameter-0-1-4-shrink-3-1: calc($hs-tubing-diameter-0-1-4 / 3);
$hs-tubing-diameter-0-3-8-shrink-3-1: calc($hs-tubing-diameter-0-3-8 / 3);
$hs-tubing-diameter-0-1-2-shrink-3-1: calc($hs-tubing-diameter-0-1-2 / 3);
$hs-tubing-diameter-0-5-8-shrink-3-1: calc($hs-tubing-diameter-0-5-8 / 3);
$hs-tubing-diameter-0-3-4-shrink-3-1: calc($hs-tubing-diameter-0-3-4 / 3);
$hs-tubing-diameter-1-0-0-shrink-3-1: calc($hs-tubing-diameter-1-0-0 / 3);
$hs-tubing-diameter-1-1-4-shrink-3-1: calc($hs-tubing-diameter-1-1-4 / 3);
$hs-tubing-diameter-1-1-2-shrink-3-1: calc($hs-tubing-diameter-1-1-2 / 3);
$hs-tubing-diameter-2-0-0-shrink-3-1: calc($hs-tubing-diameter-2-0-0 / 3);
