@import '../../variables';
.drawing {
  top: 0;
  left: 0;
  min-width: 100%;
  max-width: 100%;
  // Full height - footer height - header-height
  height: calc(100% - 77px - 40px);
  margin-top: 40px;
  position: absolute;
  display: inline-block;
  @media (max-width: 1199.98px) {
    margin-top: 0px;
    height: calc(100% - 77px);
  }
}

// shaders to darken the "unprintable" area
.shade {
  position: absolute;
  background: transparent;
  z-index: 1;
  pointer-events: none;
}
