// Lab Footer CSS
// ----------------------------------------

// Imports
// -------------------------

@import '../../variables';

// Footer
// -------------------------

.hs-footer {
  align-content: flex-start;
  align-items: flex-start;
  display: flex;
  flex-flow: column wrap;
  gap: 0;
  justify-content: flex-start;
  margin: 30px auto;
  padding: 0;
  position: relative;
  width: 100%;

  > p {
    color: $hs-shade-900;
    font-family:
      'Raleway',
      -apple-system,
      BlinkMacSystemFont,
      'Segoe UI',
      Roboto,
      'Helvetica Neue',
      Arial,
      'Noto Sans',
      sans-serif,
      'Apple Color Emoji',
      'Segoe UI Emoji',
      'Segoe UI Symbol',
      'Noto Color Emoji';
    font-size: 1rem; // 16px
    margin: 0 0 15px;
    position: relative;
  }
}
