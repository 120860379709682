// Reorder CSS
// ----------------------------------------

// Imports
// -------------------------

@import '../../../variables';

// Reorder
// -------------------------

.hs-reorder-dropdown {
  display: none;
  &.show {
    display: block;
  }
  &,
  &.dropdown,
  &.hs-dropdown {
    margin: 5px;
    max-width: 100%;
    .hs-dropdown-toggle {
      &,
      &.btn {
        margin: 0;

        &::after {
          display: none;
        }

        &.show {
          background-color: $hs-color-active;
          border-bottom-left-radius: 0 !important;
          border-bottom-right-radius: 0 !important;
          border-color: $hs-color-active;
          color: $hs-shade-white;
          margin-bottom: -5px;
          padding-bottom: 10px;

          &:focus,
          &.focus,
          &:hover {
            background-color: $hs-color-active;
            border-color: $hs-color-active;
            color: $hs-shade-white;
          }
        }
      }
    }
    .hs-dropdown-item {
      &,
      &.dropdown-item {
        display: flex;
        flex-direction: row;
        .dropdown-image {
          img {
            width: 100%;
            max-width: 100px;
          }
          padding-right: 12px;
        }
        // TODO: Add styles for tubing image
        .dropdown-text {
          > strong,
          > span {
            display: block;
            white-space: normal;
          }
        }
      }
    }

    .hs-dropdown-menu {
      left: auto;
      margin: 0px 0 0;
      max-width: 50%;
      min-width: 30%;
      right: 0px;
      top: 100%;
      padding: 0 !important;
      border-top: 6px solid $hs-color-active;

      &.show {
        border-top-left-radius: 5 !important;
        border-top-right-radius: 0 !important;
      }
    }
  }
}

// Media Queries
// -------------------------

// X-Large Devices (large desktops, less than 1400px)
// @media (max-width: 1399.98px) {

// }

// Large Devices (desktops, less than 1200px)
// @media (max-width: 1199.98px) {

// }

// Medium Devices (tablets, less than 992px)
// @media (max-width: 991.98px) {

// }

// Small Devices (landscape phones, less than 768px)
// @media (max-width: 767.98px) {

// }

// X-Small Devices (portrait phones, less than 576px)
@media (max-width: 575.98px) {
  .hs-reorder-dropdown {
    &,
    &.dropdown,
    &.hs-dropdown {
      margin: 0;
      min-width: 100%;

      .hs-dropdown-toggle {
        &,
        &.btn {
          margin: 5px 0;
          min-width: 100%;

          &.show {
            margin-bottom: 5px;
            padding-bottom: 5px;
          }
        }
      }

      .hs-dropdown-menu {
        margin: 0;
      }
    }
  }
}
