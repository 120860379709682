// Tooltip CSS
// --------------------------------------------------

// Imports
// -------------------------

@import '../../variables';

// Tooltip
// -------------------------

.hs-tooltip {
  &,
  &.tooltip {
    display: none;

    .hs-tooltip-inner {
      &,
      &.tooltip-inner {
        padding: 0.25rem 0.75rem 0.375rem; // 4px 12px 6px
      }
    }

    &.bs-tooltip-bottom {
      .hs-tooltip-arrow {
        &,
        &.tooltip-arrow {
          bottom: 100%;
          left: 50%;
          margin: 0 0 -0.4rem -0.4rem;

          &::before {
            bottom: 0;
          }
        }
      }
    }

    &.bs-tooltip-left,
    &.bs-tooltip-right {
      .hs-tooltip-arrow {
        &,
        &.tooltip-arrow {
          height: 0.8rem;
          margin: -0.4rem 0 0;
          top: 50%;
          width: 0.4rem;
        }
      }
    }

    &.bs-tooltip-left {
      .hs-tooltip-arrow {
        &,
        &.tooltip-arrow {
          left: 100%;

          &::before {
            border-width: 0.4rem 0 0.4rem 0.4rem;
            border-left-color: #18263f;
            left: 0;
          }
        }
      }
    }

    &.bs-tooltip-right {
      .hs-tooltip-arrow {
        &,
        &.tooltip-arrow {
          right: 100%;

          &::before {
            border-width: 0.4rem 0.4rem 0.4rem 0;
            border-right-color: #18263f;
            right: 0;
          }
        }
      }
    }

    &.bs-tooltip-top {
      .hs-tooltip-arrow {
        &,
        &.tooltip-arrow {
          left: 50%;
          margin: -0.4rem 0 0 -0.4rem;
          top: 100%;

          &::before {
            top: 0;
          }
        }
      }
    }

    &.show {
      display: block;
    }
  }
}
