@import '../../variables';

.hs-nav-item {
  &,
  &.btn {
    background-color: transparent !important;
    border-radius: 0;
    border-width: 0;
    color: $hs-color-active !important;
    font-size: 2rem; // 32px
    font-weight: bold;
    margin: 0 0 2px;
    max-width: 100%;
    min-height: $hs-nav-size;
    min-width: 100%;
    padding: 8px 12px 8px 10px;
    position: relative;
    width: 100%;

    svg {
      &,
      &:not(:root) {
        display: inline-block;
        fill: currentcolor;
        font-size: inherit;
        height: 2rem; // 32px
        margin: 0;
        overflow: visible;
        position: relative;
        vertical-align: -0.125em;
        width: 2rem; // 32px

        &.hs-svg-print {
          height: 2.5rem; // 40px
          vertical-align: -0.25em;
          width: 2.5rem; // 40px
        }
      }
    }

    &:focus,
    &:hover {
      background-color: rgba($hs-shade-white, 0.2) !important;
    }

    &:disabled,
    &.disabled {
      svg {
        color: rgba($hs-color-active, 0.33);
      }
    }

    &.active {
      &,
      &:focus,
      &:hover {
        background-color: $hs-shade-white !important;
        color: $hs-color-active !important;
        pointer-events: none !important;
      }
    }

    &::after {
      background-color: #b2d0e0; // rgba($hs-shade-white, .33);
      content: ' ';
      height: 2px;
      left: 0;
      margin: 0;
      position: absolute;
      right: 2px;
      top: 100%;
    }
  }
  @media (max-width: 1199.98px) {
    &,
    &.btn {
      margin: 0 2px 0 0;
      max-width: $hs-nav-size;
      min-width: $hs-nav-size;

      &::before {
        background-color: #b2d0e0; // rgba($hs-shade-white, .33);
        bottom: 2px;
        content: ' ';
        height: 3px;
        left: 0;
        position: absolute;
        right: 0;
        top: 100%;
      }
    }
  }
}

.TF .hs-nav-item {
  &,
  &.btn {
    color: #fff !important;

    &:focus,
    &:hover {
      background-color: rgba($hs-shade-white, 0.2) !important;
    }

    &:disabled,
    &.disabled {
      svg {
        color: rgba($hs-shade-black, 0.33);
      }
    }

    &.active {
      &,
      &:focus,
      &:hover {
        background-color: $hs-shade-white !important;
        color: $hs-color-active !important;
      }
    }

    &::after {
      background-color: rgba(
        $hs-shade-white,
        0.33
      ); // rgba($hs-shade-white, .33);
    }
  }
  @media (max-width: 1199.98px) {
    &,
    &.btn {
      &::before {
        background-color: rgba(
          $hs-shade-black,
          0.33
        ); // rgba($hs-shade-white, .33);
      }
    }
  }
}

.hs-nav {
  align-content: flex-start;
  align-items: flex-start;
  background-color: $hs-color-nav-bg;
  display: flex;
  flex-flow: column wrap;
  flex-grow: 0;
  gap: 0;
  justify-content: flex-start;
  margin: 0;
  max-width: $hs-nav-size;
  min-width: $hs-nav-size;
  padding: 0;
  position: relative;
  @media (max-width: 1199.98px) {
    flex-flow: row nowrap;
    justify-content: center;
    margin: 0 0 3px;
    min-width: 100%;
    order: 1;
  }
}

.WC .hs-nav {
  background-color: $hs-color-nav-bg;
}

.TF .hs-nav {
  background-color: $hs-color-tf-bg;
}
