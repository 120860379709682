// Base Modal CSS
// --------------------------------------------------

// Imports
// -------------------------

@import '../../variables';

// Modal
// -------------------------

.hs-modal-title {
  color: $hs-color-dark;
  font-family:
    'Montserrat',
    -apple-system,
    BlinkMacSystemFont,
    'Segoe UI',
    Roboto,
    'Helvetica Neue',
    Arial,
    'Noto Sans',
    sans-serif,
    'Apple Color Emoji',
    'Segoe UI Emoji',
    'Segoe UI Symbol',
    'Noto Color Emoji';
  font-size: 1rem; // 16px
  font-weight: 600;
  text-transform: uppercase;
}

.hs-modal-close {
  background-image: none;
  color: rgba($hs-color-dark, 0.5);
  font-size: 1.125rem; // 18px
  line-height: 1;
  margin-right: 0 !important;
  opacity: 1;

  &:focus,
  &:hover {
    color: $hs-color-dark;
    text-decoration: none;
  }
}

.WC .hs-modal-header {
  background-color: $hs-modal-header-bg;
  border-bottom-width: 0;
  padding: 10px 15px;
}

.TF .hs-modal-header {
  background-color: $hs-modal-header-bg2;
  border-bottom-width: 0;
  padding: 10px 15px;
}

.hs-modal-body {
  padding: 10px 15px;
  text-align: left;
}

.hs-modal-footer {
  border-top-width: 0;
  display: none;
  justify-content: center;
  margin: 5px 0 0;
  padding: 10px;
  position: relative;

  &::after {
    background-color: $hs-shade-200;
    bottom: 100%;
    content: ' ';
    display: none;
    flex: none;
    height: 3px;
    left: 0;
    margin: 0;
    position: absolute;
    right: 0;
  }

  &.space-between {
    justify-content: space-between;
  }

  &.show-border {
    &::after {
      display: block;
    }
  }

  &.show {
    display: flex;
  }
}

.hs-modal-content {
  -webkit-box-shadow: 0px 3px 6px 0px rgba(0, 0, 0, 0.25);
  -moz-box-shadow: 0px 3px 6px 0px rgba(0, 0, 0, 0.25);
  box-shadow: 0px 3px 6px 0px rgba(0, 0, 0, 0.25);
}

.hs-modal-dialog {
  z-index: 1;
}

.modal-backdrop {
  background-color: rgba($hs-shade-black, 0.33);
  z-index: 0;
}

.hs-modal {
  &.show {
    display: block;
  }
}
