// Choose Quantity CSS
// --------------------------------------------------

// Dropdown Custom Style
// -------------------------

.custom.dropdown-menu {
  width: 100%;
  padding: 0;
  height: 500%;
  overflow-y: scroll;
}

.custom > .dropdown > .dropdown-toggle {
  display: inline-flex;
  border-bottom: 1px solid lightgray;
  height: 70px;
}

.custom.dropdown-item {
  display: inline-flex;
  border-bottom: 1px solid lightgray;
  height: 70px;
}

.choose-quantity {
  margin: auto 0;
}
